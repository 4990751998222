const LocationReviewsData = [
    {
        "date": "2024-09-18",
        "review_rate": 4.8,
        "customer_name": "Mark Porter",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "William K.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-27",
        "review_rate": 4.8,
        "customer_name": "Jason Lowery",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Robert B.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-26",
        "review_rate": 5.0,
        "customer_name": "Richard Horne",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jordan M.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-17",
        "review_rate": 5.0,
        "customer_name": "Benjamin Buck",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Wanda H.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-29",
        "review_rate": 4.9,
        "customer_name": "Colin Aguirre",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Kathy B.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-09",
        "review_rate": 4.9,
        "customer_name": "Robert Morris",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Kyle S.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-24",
        "review_rate": 4.8,
        "customer_name": "Stephanie Cummings",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Johnny W.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-05",
        "review_rate": 4.9,
        "customer_name": "Marissa Cannon",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Roger W.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-09",
        "review_rate": 4.9,
        "customer_name": "Michael Miller",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Debra C.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-12",
        "review_rate": 4.8,
        "customer_name": "Mathew Johnson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Derrick J.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.9,
        "customer_name": "Theresa Price",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jesse Y.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.9,
        "customer_name": "Tamara Day",
        "service_type": "Garage Door Insulation",
        "tech_name": "Derrick D.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.9,
        "customer_name": "Laura Gallegos",
        "service_type": "Garage Door Installation",
        "tech_name": "Erica W.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-16",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Bailey",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Amy J.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-02",
        "review_rate": 5.0,
        "customer_name": "Christian Lowe",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Christine S.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.9,
        "customer_name": "Courtney Bennett",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Anthony P.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-10-04",
        "review_rate": 4.8,
        "customer_name": "Stephen Turner",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Paula W.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-15",
        "review_rate": 5.0,
        "customer_name": "Vanessa Clark",
        "service_type": "Garage Door Insulation",
        "tech_name": "Penny V.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.8,
        "customer_name": "Matthew Serrano",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Seth H.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.8,
        "customer_name": "Gary Solomon",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Danielle S.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-09-27",
        "review_rate": 4.9,
        "customer_name": "Anthony Gomez",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Stephanie S.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-01",
        "review_rate": 5.0,
        "customer_name": "Jane Keith",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Brianna W.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-16",
        "review_rate": 4.9,
        "customer_name": "Angel Miles",
        "service_type": "Garage Door Opener",
        "tech_name": "William M.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-16",
        "review_rate": 4.9,
        "customer_name": "Patricia Church",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Benjamin T.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-10-05",
        "review_rate": 4.9,
        "customer_name": "Wendy Weaver",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Elizabeth H.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-06",
        "review_rate": 4.9,
        "customer_name": "James Harris",
        "service_type": "Garage Door Off Track",
        "tech_name": "William N.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-10-29",
        "review_rate": 4.8,
        "customer_name": "Haley Hampton",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Sharon G.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-11",
        "review_rate": 5.0,
        "customer_name": "Kimberly Sanchez",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Joshua S.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-05",
        "review_rate": 5.0,
        "customer_name": "Mark Jackson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Debra R.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-10-17",
        "review_rate": 5.0,
        "customer_name": "Nicole Fowler",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Alexandra C.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-01",
        "review_rate": 4.8,
        "customer_name": "Sarah Ramirez",
        "service_type": "Garage Door Services",
        "tech_name": "Nicholas D.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-04",
        "review_rate": 4.9,
        "customer_name": "Kelly Pham",
        "service_type": "Garage Door Installation",
        "tech_name": "Jason B.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-21",
        "review_rate": 4.9,
        "customer_name": "Jamie Baker",
        "service_type": "Garage Door Insulation",
        "tech_name": "Gregory J.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-10-24",
        "review_rate": 4.9,
        "customer_name": "Jasmine Miller",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Catherine P.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-05",
        "review_rate": 4.8,
        "customer_name": "Tammy Romero",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Melanie C.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-10-05",
        "review_rate": 4.9,
        "customer_name": "Brandy Reed",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "John W.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.9,
        "customer_name": "James Carter",
        "service_type": "Garage Door Insulation",
        "tech_name": "Kristina P.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-18",
        "review_rate": 4.9,
        "customer_name": "Brandy Brown",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Kenneth C.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-14",
        "review_rate": 5.0,
        "customer_name": "Gregory Gibson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Nicole P.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.8,
        "customer_name": "Erin Hughes",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Patrick T.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-10-29",
        "review_rate": 5.0,
        "customer_name": "Jennifer Leonard",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Lisa O.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-21",
        "review_rate": 4.9,
        "customer_name": "Austin Goodwin",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Derek H.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-29",
        "review_rate": 4.8,
        "customer_name": "Maureen Robles",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Alicia O.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.9,
        "customer_name": "Veronica Rios",
        "service_type": "Garage Door Installation",
        "tech_name": "Antonio S.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-03",
        "review_rate": 4.8,
        "customer_name": "Cheryl Williams",
        "service_type": "Garage Door Installation",
        "tech_name": "Kelly J.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-20",
        "review_rate": 4.9,
        "customer_name": "Jessica Patel",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Kimberly R.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-01",
        "review_rate": 5.0,
        "customer_name": "Cassandra Robles",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Katherine P.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-01",
        "review_rate": 4.9,
        "customer_name": "Jacob Vazquez",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Joan C.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-10",
        "review_rate": 4.8,
        "customer_name": "Carol Oconnor",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Erica C.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-08",
        "review_rate": 4.9,
        "customer_name": "Kyle Livingston",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "William M.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-21",
        "review_rate": 4.9,
        "customer_name": "Samantha Simmons",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Debra W.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-01",
        "review_rate": 4.9,
        "customer_name": "Andrew Perez",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jeffrey S.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-03",
        "review_rate": 4.9,
        "customer_name": "John Levine",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Lee R.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-13",
        "review_rate": 4.9,
        "customer_name": "Mark Patterson",
        "service_type": "Garage Door Installation",
        "tech_name": "Thomas T.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-07",
        "review_rate": 4.9,
        "customer_name": "Zachary Stevens",
        "service_type": "Garage Door Off Track",
        "tech_name": "Kim H.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-19",
        "review_rate": 4.8,
        "customer_name": "Shirley Hurley",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Ronald F.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-31",
        "review_rate": 4.9,
        "customer_name": "Erika Ramirez",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Tina F.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-11",
        "review_rate": 4.8,
        "customer_name": "Lori Singh",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jacob B.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-06",
        "review_rate": 4.8,
        "customer_name": "Kristin Williams",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Zachary R.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-09",
        "review_rate": 5.0,
        "customer_name": "Veronica Little",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Kristin S.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-09-17",
        "review_rate": 5.0,
        "customer_name": "David Smith",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Johnny L.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-08",
        "review_rate": 4.9,
        "customer_name": "Jeffery Morrison",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Debra C.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-09",
        "review_rate": 5.0,
        "customer_name": "Danielle Klein",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Nicholas S.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-29",
        "review_rate": 4.9,
        "customer_name": "Brittany Wolfe",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Paige S.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-09-20",
        "review_rate": 5.0,
        "customer_name": "Lisa Kim",
        "service_type": "Garage Door Services",
        "tech_name": "Kenneth R.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-23",
        "review_rate": 5.0,
        "customer_name": "Marie Benitez",
        "service_type": "Garage Door Installation",
        "tech_name": "John L.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.8,
        "customer_name": "Deanna Hawkins",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Kenneth L.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-09-15",
        "review_rate": 5.0,
        "customer_name": "Virginia Medina",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Timothy O.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-13",
        "review_rate": 5.0,
        "customer_name": "Kelsey Cook",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Kelsey D.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-31",
        "review_rate": 4.9,
        "customer_name": "Sharon Flores",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "James W.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-09",
        "review_rate": 4.9,
        "customer_name": "Christine Long",
        "service_type": "Garage Door Off Track",
        "tech_name": "Michael C.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-29",
        "review_rate": 5.0,
        "customer_name": "Victoria Prince",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Courtney M.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.9,
        "customer_name": "Micheal Walker",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Leslie C.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-02",
        "review_rate": 4.8,
        "customer_name": "Linda Stone",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Tamara W.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-18",
        "review_rate": 5.0,
        "customer_name": "Jasmin Avila",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Rachael T.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-22",
        "review_rate": 4.9,
        "customer_name": "Lori Smith",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Johnathan R.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-25",
        "review_rate": 4.9,
        "customer_name": "Jorge Weiss",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Megan P.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-28",
        "review_rate": 4.9,
        "customer_name": "Bradley Ferrell",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Amy E.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-27",
        "review_rate": 4.8,
        "customer_name": "Dylan Humphrey",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Derek Q.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-03",
        "review_rate": 4.9,
        "customer_name": "Heather Holland",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Colleen M.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-26",
        "review_rate": 4.9,
        "customer_name": "Kimberly Hall",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Katie H.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-09-10",
        "review_rate": 4.9,
        "customer_name": "Danielle Griffith",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jesse W.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-09-17",
        "review_rate": 4.9,
        "customer_name": "Richard Henderson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jennifer G.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-26",
        "review_rate": 5.0,
        "customer_name": "Austin Thomas",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Tammy H.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-09-03",
        "review_rate": 4.9,
        "customer_name": "Jessica Gonzalez",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Alfred B.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.9,
        "customer_name": "Frank Davis",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Paige B.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-30",
        "review_rate": 4.8,
        "customer_name": "Travis Davis",
        "service_type": "Garage Door Services",
        "tech_name": "Gregory J.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-14",
        "review_rate": 4.8,
        "customer_name": "Sabrina Welch",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Joseph D.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-09-30",
        "review_rate": 5.0,
        "customer_name": "Jill Quinn",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Megan M.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-29",
        "review_rate": 4.8,
        "customer_name": "Sheri Mcguire",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Jessica C.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-01",
        "review_rate": 4.9,
        "customer_name": "Susan Black",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Leah K.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-25",
        "review_rate": 4.9,
        "customer_name": "Charles Moore",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Andrew W.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-09",
        "review_rate": 4.9,
        "customer_name": "Amanda Ferguson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Christopher B.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-09",
        "review_rate": 4.9,
        "customer_name": "Michelle Henry",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jonathan H.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-29",
        "review_rate": 4.8,
        "customer_name": "Gary Downs",
        "service_type": "Garage Door Installation",
        "tech_name": "Michelle W.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-09-25",
        "review_rate": 4.8,
        "customer_name": "Deborah Nguyen",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Emily S.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-24",
        "review_rate": 4.9,
        "customer_name": "Jesus Holmes",
        "service_type": "Garage Door Insulation",
        "tech_name": "Dorothy W.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.9,
        "customer_name": "Terry Dalton",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Theodore B.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-12",
        "review_rate": 4.9,
        "customer_name": "Andrea Cook",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Rebecca G.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-06",
        "review_rate": 4.9,
        "customer_name": "Joseph Atkinson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Laura S.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-27",
        "review_rate": 4.9,
        "customer_name": "Bryan Mcdonald",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Lori A.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-19",
        "review_rate": 5.0,
        "customer_name": "James Sheppard",
        "service_type": "Garage Door Installation",
        "tech_name": "Kari T.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.9,
        "customer_name": "Derek Macias",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Robert S.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-31",
        "review_rate": 4.8,
        "customer_name": "Bryan Griffin",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Patricia C.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.9,
        "customer_name": "Joe Barton",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Patrick R.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.9,
        "customer_name": "Taylor Guerrero",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Anna H.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-31",
        "review_rate": 5.0,
        "customer_name": "Dustin Payne",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Tracy M.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.8,
        "customer_name": "Donald Duke",
        "service_type": "Garage Door Repair",
        "tech_name": "Jacqueline C.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-20",
        "review_rate": 4.9,
        "customer_name": "Sarah Nelson",
        "service_type": "Garage Door Off Track",
        "tech_name": "David G.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-01",
        "review_rate": 4.9,
        "customer_name": "Matthew Vasquez",
        "service_type": "Garage Door Off Track",
        "tech_name": "Brandon R.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-01",
        "review_rate": 5.0,
        "customer_name": "Kelly Jones",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jeremy B.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-17",
        "review_rate": 4.9,
        "customer_name": "Christian Carr",
        "service_type": "Garage Door Insulation",
        "tech_name": "Daniel W.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-07",
        "review_rate": 4.9,
        "customer_name": "Kevin Harper",
        "service_type": "Garage Door Services",
        "tech_name": "Sophia K.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-22",
        "review_rate": 4.9,
        "customer_name": "Angela Mack",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Joshua W.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-25",
        "review_rate": 5.0,
        "customer_name": "Stephanie English",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Brian M.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-23",
        "review_rate": 5.0,
        "customer_name": "Christopher Shaw",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Melissa W.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.9,
        "customer_name": "Amanda Williams",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Karen R.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-02",
        "review_rate": 5.0,
        "customer_name": "Anthony Sandoval",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jordan J.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-13",
        "review_rate": 5.0,
        "customer_name": "Janice Roy",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Kenneth S.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-09-28",
        "review_rate": 4.9,
        "customer_name": "Anthony Davidson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jeffery J.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-18",
        "review_rate": 4.9,
        "customer_name": "Diane Ramsey",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Connie J.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-02",
        "review_rate": 4.9,
        "customer_name": "Jonathan Stokes",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Mark J.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-01",
        "review_rate": 4.8,
        "customer_name": "Christina Lawrence",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Susan W.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-26",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Cook",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Larry S.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-16",
        "review_rate": 4.9,
        "customer_name": "Paul Reyes",
        "service_type": "Garage Door Opener",
        "tech_name": "Bradley W.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-26",
        "review_rate": 4.8,
        "customer_name": "Mrs. Lindsay Moody PhD",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Deborah H.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.9,
        "customer_name": "Derek Mckinney",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Tristan P.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-18",
        "review_rate": 4.9,
        "customer_name": "Chelsea Morrison",
        "service_type": "Garage Door Repair",
        "tech_name": "Jeffery T.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-24",
        "review_rate": 4.8,
        "customer_name": "Dominique Martinez",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Bruce G.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-19",
        "review_rate": 5.0,
        "customer_name": "Alicia Johnson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Amy G.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.8,
        "customer_name": "Michelle Thompson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Christopher L.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-24",
        "review_rate": 4.8,
        "customer_name": "Deborah Johnson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Randy H.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-27",
        "review_rate": 5.0,
        "customer_name": "Maria Anderson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Michael S.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-24",
        "review_rate": 5.0,
        "customer_name": "Levi Brown",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Ann H.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-12",
        "review_rate": 4.8,
        "customer_name": "Cynthia Guerra",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Meredith J.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-10",
        "review_rate": 5.0,
        "customer_name": "April Austin",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Heather J.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-07",
        "review_rate": 4.8,
        "customer_name": "David Perkins",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Christy B.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-04",
        "review_rate": 5.0,
        "customer_name": "William Salinas",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Albert W.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-31",
        "review_rate": 5.0,
        "customer_name": "Caleb Ward",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Chelsea C.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-07",
        "review_rate": 5.0,
        "customer_name": "Kevin Cross",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Tina A.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-01",
        "review_rate": 4.8,
        "customer_name": "Ryan Stewart",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Dennis C.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-25",
        "review_rate": 4.9,
        "customer_name": "Troy Mullins",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Marcia D.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-23",
        "review_rate": 4.9,
        "customer_name": "Kelly Mcneil",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Cameron T.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-30",
        "review_rate": 4.9,
        "customer_name": "Robert Price",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Jennifer B.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-26",
        "review_rate": 4.9,
        "customer_name": "Linda Ross",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Steven G.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-06",
        "review_rate": 5.0,
        "customer_name": "Paula Jones",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Christian M.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-18",
        "review_rate": 4.9,
        "customer_name": "David Jordan",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Lauren O.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-20",
        "review_rate": 5.0,
        "customer_name": "Nicole Dean",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Thomas F.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.9,
        "customer_name": "Scott Riggs",
        "service_type": "Garage Door Repair",
        "tech_name": "Kevin J.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-25",
        "review_rate": 5.0,
        "customer_name": "Amy Mcdonald",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Timothy O.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-31",
        "review_rate": 4.9,
        "customer_name": "Dennis Roberts",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Brett C.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-10",
        "review_rate": 4.9,
        "customer_name": "Jill Reed",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Lori G.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-16",
        "review_rate": 4.9,
        "customer_name": "Samantha Gallegos",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Erica D.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-10",
        "review_rate": 4.9,
        "customer_name": "Stephanie Olson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Monica H.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-09",
        "review_rate": 5.0,
        "customer_name": "Stephanie Mendez",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Laurie E.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-13",
        "review_rate": 4.9,
        "customer_name": "Danielle Nguyen",
        "service_type": "Garage Door Off Track",
        "tech_name": "Christine C.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-09-29",
        "review_rate": 4.8,
        "customer_name": "Kelly Andrews",
        "service_type": "Garage Door Insulation",
        "tech_name": "Denise N.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.8,
        "customer_name": "Amber Guzman",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Kari K.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-08",
        "review_rate": 4.8,
        "customer_name": "Steven Sherman",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Kimberly M.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-04",
        "review_rate": 5.0,
        "customer_name": "Derrick Mays",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "David C.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-20",
        "review_rate": 4.8,
        "customer_name": "David Jones",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Kelly S.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-25",
        "review_rate": 4.9,
        "customer_name": "Patricia Huff",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Sarah I.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-10",
        "review_rate": 4.8,
        "customer_name": "Edwin James",
        "service_type": "Garage Door Installation",
        "tech_name": "Francis J.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-18",
        "review_rate": 4.9,
        "customer_name": "Steven Roth",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Eileen A.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-11",
        "review_rate": 5.0,
        "customer_name": "Jenna Young",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Adam H.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.8,
        "customer_name": "Virginia Zamora MD",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Scott D.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-19",
        "review_rate": 4.8,
        "customer_name": "Gerald Galvan",
        "service_type": "Garage Door Off Track",
        "tech_name": "Leah G.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-13",
        "review_rate": 4.8,
        "customer_name": "Miguel Kennedy",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Theodore J.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-23",
        "review_rate": 5.0,
        "customer_name": "Shane Drake",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Vicki G.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-03",
        "review_rate": 5.0,
        "customer_name": "Jessica Townsend",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Sarah N.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-07",
        "review_rate": 4.8,
        "customer_name": "Deanna Good",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Christopher B.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.9,
        "customer_name": "Ellen Walker",
        "service_type": "Garage Door Opener",
        "tech_name": "Stephanie M.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-30",
        "review_rate": 4.9,
        "customer_name": "Mary Todd",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jacob H.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-14",
        "review_rate": 4.8,
        "customer_name": "Joshua Thomas",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Michael S.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-15",
        "review_rate": 4.9,
        "customer_name": "Michelle Meadows",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Joseph O.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-15",
        "review_rate": 4.8,
        "customer_name": "Steven Anderson",
        "service_type": "Garage Door Services",
        "tech_name": "Peter W.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-17",
        "review_rate": 4.9,
        "customer_name": "Michelle Howard",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Mark J.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-10",
        "review_rate": 5.0,
        "customer_name": "Mr. Joseph Flores",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Nancy A.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-20",
        "review_rate": 4.9,
        "customer_name": "Billy Stanton",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Kristine R.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-11",
        "review_rate": 5.0,
        "customer_name": "Grace Nguyen",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jeffrey P.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-20",
        "review_rate": 4.9,
        "customer_name": "Casey Crawford",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Ricky G.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-27",
        "review_rate": 4.9,
        "customer_name": "Misty Decker",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Joseph H.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-17",
        "review_rate": 4.8,
        "customer_name": "Mark Thompson",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Amanda H.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-22",
        "review_rate": 4.9,
        "customer_name": "Jerry Carlson Jr.",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Walter A.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.8,
        "customer_name": "Evan York",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Brian H.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-04",
        "review_rate": 4.9,
        "customer_name": "Michael Cooper",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Catherine E.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-13",
        "review_rate": 4.9,
        "customer_name": "Samantha Griffin",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Francis W.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-16",
        "review_rate": 4.8,
        "customer_name": "Taylor Hayden",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Paula G.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-08",
        "review_rate": 4.9,
        "customer_name": "Diane Mitchell",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Ricardo H.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-18",
        "review_rate": 5.0,
        "customer_name": "Mitchell Allison",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Lonnie O.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-15",
        "review_rate": 4.8,
        "customer_name": "James Porter",
        "service_type": "Garage Door Insulation",
        "tech_name": "Nathan C.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-20",
        "review_rate": 5.0,
        "customer_name": "Kristine Tran",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jonathan S.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-13",
        "review_rate": 4.8,
        "customer_name": "Aimee Parker MD",
        "service_type": "Garage Door Repair",
        "tech_name": "Sherri L.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-13",
        "review_rate": 4.8,
        "customer_name": "Cody Cowan",
        "service_type": "Garage Door Installation",
        "tech_name": "Philip O.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-19",
        "review_rate": 4.9,
        "customer_name": "Wesley Morris",
        "service_type": "Garage Door Services",
        "tech_name": "Charles D.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.8,
        "customer_name": "Glenda Walker",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Monica S.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-08",
        "review_rate": 5.0,
        "customer_name": "Crystal Anderson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Trevor B.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-19",
        "review_rate": 4.9,
        "customer_name": "Joseph Mendez",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Patty A.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-02",
        "review_rate": 5.0,
        "customer_name": "Timothy Erickson",
        "service_type": "Garage Door Services",
        "tech_name": "Wesley R.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-09-28",
        "review_rate": 4.9,
        "customer_name": "Tasha Briggs",
        "service_type": "Garage Door Services",
        "tech_name": "Juan S.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-10",
        "review_rate": 4.9,
        "customer_name": "Katie Williams",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Brandon C.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-23",
        "review_rate": 5.0,
        "customer_name": "Terry Nguyen",
        "service_type": "Garage Door Services",
        "tech_name": "Travis J.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-23",
        "review_rate": 4.8,
        "customer_name": "David Cook",
        "service_type": "Garage Door Opener",
        "tech_name": "Joseph B.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-18",
        "review_rate": 4.9,
        "customer_name": "Jose Thomas",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Todd N.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-09",
        "review_rate": 4.8,
        "customer_name": "Cathy Carney",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Natalie T.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-29",
        "review_rate": 4.9,
        "customer_name": "Alex Smith",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Ebony W.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-24",
        "review_rate": 5.0,
        "customer_name": "Brittany Kim",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jacqueline P.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.9,
        "customer_name": "Maureen Sanders",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Justin M.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-26",
        "review_rate": 4.9,
        "customer_name": "Kirsten Rangel",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Tina K.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-17",
        "review_rate": 4.9,
        "customer_name": "Debra Edwards",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Antonio P.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-25",
        "review_rate": 4.8,
        "customer_name": "Cheryl Page",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Kayla R.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-12",
        "review_rate": 4.9,
        "customer_name": "Nancy Booth",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Richard M.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-15",
        "review_rate": 4.9,
        "customer_name": "Timothy Graham",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Steven M.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-27",
        "review_rate": 4.9,
        "customer_name": "Andrew Chung",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Tricia P.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-25",
        "review_rate": 4.8,
        "customer_name": "Evelyn Parker",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Kelly S.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.9,
        "customer_name": "Reginald Avery",
        "service_type": "Garage Door Repair",
        "tech_name": "Tammy M.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.9,
        "customer_name": "Richard Daniels V",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Steven R.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-29",
        "review_rate": 4.9,
        "customer_name": "Jason Nelson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Christina F.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-11",
        "review_rate": 5.0,
        "customer_name": "Kimberly Robinson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Lynn M.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-08",
        "review_rate": 4.9,
        "customer_name": "Daniel Hall",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Manuel A.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-30",
        "review_rate": 4.8,
        "customer_name": "Carlos Marquez",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "David A.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-09",
        "review_rate": 4.9,
        "customer_name": "Michael Finley",
        "service_type": "Garage Door Installation",
        "tech_name": "Jeffrey B.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-24",
        "review_rate": 4.9,
        "customer_name": "Toni Johnson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Shawn M.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-28",
        "review_rate": 4.9,
        "customer_name": "Pamela Anthony",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Frederick B.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-24",
        "review_rate": 4.9,
        "customer_name": "Keith Jacobs",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Andrew D.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-15",
        "review_rate": 4.9,
        "customer_name": "Timothy Reynolds",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Rebecca P.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-05",
        "review_rate": 4.8,
        "customer_name": "Paul Green",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Michael C.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-11",
        "review_rate": 4.9,
        "customer_name": "Ashley Jennings",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Matthew J.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-23",
        "review_rate": 4.8,
        "customer_name": "Chad Moreno",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Daniel M.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.8,
        "customer_name": "Amy Nichols",
        "service_type": "Garage Door Insulation",
        "tech_name": "Samantha M.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-12",
        "review_rate": 4.8,
        "customer_name": "Jacqueline Moore",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Maria P.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.9,
        "customer_name": "Robert Skinner",
        "service_type": "Garage Door Installation",
        "tech_name": "Monica M.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-01",
        "review_rate": 5.0,
        "customer_name": "John Gregory",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Keith W.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-05",
        "review_rate": 4.8,
        "customer_name": "Samantha Lindsey",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Kayla S.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-19",
        "review_rate": 5.0,
        "customer_name": "Christina Lawrence",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Chad J.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-06",
        "review_rate": 4.9,
        "customer_name": "Michelle Zuniga",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jason P.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.9,
        "customer_name": "Paula Daniel",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Christine D.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-20",
        "review_rate": 4.8,
        "customer_name": "Heidi Bush",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Daniel M.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-07",
        "review_rate": 4.9,
        "customer_name": "Ana Adams",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Karen C.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-07",
        "review_rate": 5.0,
        "customer_name": "Peter Baker",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "John W.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-02",
        "review_rate": 4.9,
        "customer_name": "Marvin James",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jermaine S.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-29",
        "review_rate": 4.9,
        "customer_name": "Zachary Gibson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "John A.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-17",
        "review_rate": 4.8,
        "customer_name": "Valerie Newman",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Alexandra J.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.8,
        "customer_name": "Shawn Finley",
        "service_type": "Garage Door Repair",
        "tech_name": "Justin M.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-10",
        "review_rate": 4.9,
        "customer_name": "Michael Patrick",
        "service_type": "Garage Door Repair",
        "tech_name": "Karen B.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-29",
        "review_rate": 5.0,
        "customer_name": "Ethan Garcia",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Tammy T.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-13",
        "review_rate": 5.0,
        "customer_name": "Brittany Curtis",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Nathan W.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-15",
        "review_rate": 4.9,
        "customer_name": "Stephanie Trevino",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Steven B.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-02",
        "review_rate": 4.9,
        "customer_name": "Mrs. Debbie Gomez",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Thomas E.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-20",
        "review_rate": 4.9,
        "customer_name": "Gregory Williams",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Timothy B.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-09",
        "review_rate": 4.9,
        "customer_name": "Dorothy Hooper",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Angela D.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-19",
        "review_rate": 4.9,
        "customer_name": "Shannon Schneider",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Robert W.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-28",
        "review_rate": 5.0,
        "customer_name": "Carla Whitehead",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Robert P.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-13",
        "review_rate": 4.8,
        "customer_name": "Sylvia Oconnor",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Jose V.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-06",
        "review_rate": 5.0,
        "customer_name": "Cheryl Owen",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Patrick P.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-09",
        "review_rate": 5.0,
        "customer_name": "Andrew Garcia",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Victor B.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-27",
        "review_rate": 4.9,
        "customer_name": "Angel Schneider",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Luke J.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-30",
        "review_rate": 4.8,
        "customer_name": "Paul Ellison",
        "service_type": "Garage Door Insulation",
        "tech_name": "Garrett R.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-21",
        "review_rate": 4.8,
        "customer_name": "Jeffrey Diaz",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Paul D.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-27",
        "review_rate": 4.9,
        "customer_name": "Jason Jones",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Joseph S.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-06",
        "review_rate": 5.0,
        "customer_name": "Diane Fox",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Timothy S.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-27",
        "review_rate": 5.0,
        "customer_name": "Kathleen Wallace",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Sandy D.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-09",
        "review_rate": 5.0,
        "customer_name": "Chloe Jackson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Samuel L.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-29",
        "review_rate": 4.9,
        "customer_name": "Cynthia Torres",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jessica J.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-09-06",
        "review_rate": 5.0,
        "customer_name": "Jim Friedman",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jeffrey G.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-14",
        "review_rate": 5.0,
        "customer_name": "William Ortiz",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Brandon R.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-09-22",
        "review_rate": 4.9,
        "customer_name": "Michael Davis",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "David T.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-05",
        "review_rate": 4.8,
        "customer_name": "Thomas Johnson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Mallory W.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-06",
        "review_rate": 5.0,
        "customer_name": "Brian Brown",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Kenneth F.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-09-11",
        "review_rate": 4.9,
        "customer_name": "Diane Randall",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Nichole W.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-15",
        "review_rate": 4.9,
        "customer_name": "Henry Jones",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jennifer R.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-05",
        "review_rate": 4.9,
        "customer_name": "Curtis Hall",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Sarah W.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-12",
        "review_rate": 4.8,
        "customer_name": "Jacob Johnston",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Diane K.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-24",
        "review_rate": 4.8,
        "customer_name": "Peggy Brown",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Judy S.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-18",
        "review_rate": 5.0,
        "customer_name": "Elizabeth Duncan",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Philip J.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-20",
        "review_rate": 5.0,
        "customer_name": "Russell Floyd",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Alexis M.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-14",
        "review_rate": 4.9,
        "customer_name": "Michael Combs",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Deborah S.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-03",
        "review_rate": 4.9,
        "customer_name": "Jacob Osborne",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Douglas D.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-09-24",
        "review_rate": 5.0,
        "customer_name": "Daniel Ford",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Michael D.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-09",
        "review_rate": 4.8,
        "customer_name": "Andrew Fowler",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Nicole B.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.8,
        "customer_name": "Brett Phillips",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Samuel B.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-24",
        "review_rate": 4.8,
        "customer_name": "Lisa Brown",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Pamela K.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-05",
        "review_rate": 4.9,
        "customer_name": "Benjamin Miller",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Candice F.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-14",
        "review_rate": 5.0,
        "customer_name": "Kelly Lindsey",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Erika R.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-24",
        "review_rate": 4.9,
        "customer_name": "Brittany Wagner",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Alan S.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-15",
        "review_rate": 4.9,
        "customer_name": "Debra Vasquez",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Dana G.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-19",
        "review_rate": 5.0,
        "customer_name": "Karen Baker",
        "service_type": "Garage Door Opener",
        "tech_name": "Heather R.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-05",
        "review_rate": 4.8,
        "customer_name": "Melissa Gomez",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Karen S.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-07",
        "review_rate": 5.0,
        "customer_name": "Henry Sutton",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Lauren W.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.8,
        "customer_name": "Tina Stokes",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Gary P.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-25",
        "review_rate": 4.8,
        "customer_name": "Ronald Ball",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Michelle J.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-05",
        "review_rate": 4.8,
        "customer_name": "Emily Gomez",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jason C.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-28",
        "review_rate": 4.9,
        "customer_name": "Angela White",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jason C.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-25",
        "review_rate": 4.9,
        "customer_name": "Edward Andrade",
        "service_type": "Garage Door Off Track",
        "tech_name": "Julie S.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-09-30",
        "review_rate": 5.0,
        "customer_name": "Jamie Anderson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Michael P.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-05",
        "review_rate": 5.0,
        "customer_name": "Jessica Bailey",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Norman H.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-29",
        "review_rate": 4.9,
        "customer_name": "Jessica Zimmerman",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Richard M.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.9,
        "customer_name": "Barbara Walker",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Lori R.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-13",
        "review_rate": 5.0,
        "customer_name": "Lisa Mckenzie",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Stephanie R.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-11",
        "review_rate": 5.0,
        "customer_name": "Bruce Bowers",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Emily R.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-09-14",
        "review_rate": 4.8,
        "customer_name": "Edward Cantu",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Alexander M.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-30",
        "review_rate": 5.0,
        "customer_name": "Thomas Bailey",
        "service_type": "Garage Door Services",
        "tech_name": "Jamie L.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-01",
        "review_rate": 5.0,
        "customer_name": "Cheyenne Duncan",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Erica K.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.9,
        "customer_name": "Deborah Swanson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Martin R.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-01",
        "review_rate": 5.0,
        "customer_name": "Nicole Williams",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Lisa H.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-26",
        "review_rate": 4.9,
        "customer_name": "William White",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Nicholas B.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.9,
        "customer_name": "Randall Holder",
        "service_type": "Garage Door Installation",
        "tech_name": "Alexandria G.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-14",
        "review_rate": 5.0,
        "customer_name": "Jeremy Woods",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Thomas S.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-09",
        "review_rate": 4.9,
        "customer_name": "Ian Holmes",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Amanda K.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-21",
        "review_rate": 4.9,
        "customer_name": "Alex Ramirez",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Nicole H.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-03",
        "review_rate": 4.8,
        "customer_name": "Robert Jordan MD",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Yvonne A.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-16",
        "review_rate": 5.0,
        "customer_name": "Tony Allen",
        "service_type": "Garage Door Services",
        "tech_name": "James H.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-16",
        "review_rate": 4.9,
        "customer_name": "Danielle Hamilton",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Charles F.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-10",
        "review_rate": 4.9,
        "customer_name": "Rodney Sparks",
        "service_type": "Garage Door Opener",
        "tech_name": "Julie R.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-20",
        "review_rate": 5.0,
        "customer_name": "Steven Levy",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Stephanie S.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-09-28",
        "review_rate": 4.9,
        "customer_name": "Amy Johnson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jerry Z.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-25",
        "review_rate": 4.9,
        "customer_name": "Nicole Williams",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Joseph M.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-17",
        "review_rate": 4.8,
        "customer_name": "Frank Haney",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Terri B.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-24",
        "review_rate": 4.8,
        "customer_name": "Mark Santos",
        "service_type": "Garage Door Repair",
        "tech_name": "Michelle D.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-01",
        "review_rate": 4.9,
        "customer_name": "Jack Watson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Gregory O.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-24",
        "review_rate": 4.9,
        "customer_name": "Sean Martin",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Danielle D.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-12",
        "review_rate": 4.8,
        "customer_name": "Cory Garrett",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Caitlin M.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-03",
        "review_rate": 4.8,
        "customer_name": "Debbie Garcia",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jean G.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-12",
        "review_rate": 4.8,
        "customer_name": "Andrew Chambers",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Lisa W.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-08",
        "review_rate": 4.9,
        "customer_name": "Jamie Andrews",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Michelle N.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-26",
        "review_rate": 4.9,
        "customer_name": "Michael Osborne",
        "service_type": "Garage Door Off Track",
        "tech_name": "Luis H.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-28",
        "review_rate": 4.8,
        "customer_name": "Kyle Spencer",
        "service_type": "Garage Door Installation",
        "tech_name": "Benjamin F.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-01",
        "review_rate": 4.8,
        "customer_name": "Jessica Anderson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Sean A.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-03",
        "review_rate": 4.8,
        "customer_name": "Steven Boyd",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jeanne N.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-16",
        "review_rate": 5.0,
        "customer_name": "Jessica Freeman",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Michael S.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-19",
        "review_rate": 5.0,
        "customer_name": "Amanda Walsh",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Mark F.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-09",
        "review_rate": 5.0,
        "customer_name": "Laurie Olson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Kimberly H.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-26",
        "review_rate": 4.9,
        "customer_name": "Andrew Day",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Ian T.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-27",
        "review_rate": 4.9,
        "customer_name": "Charles Allen",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Vanessa F.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-23",
        "review_rate": 4.8,
        "customer_name": "Joshua Goodwin",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Joshua M.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-14",
        "review_rate": 4.9,
        "customer_name": "Kim Johnson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "David S.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-17",
        "review_rate": 4.9,
        "customer_name": "Ian Butler",
        "service_type": "Garage Door Opener",
        "tech_name": "Jessica A.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-18",
        "review_rate": 4.9,
        "customer_name": "Jonathan Meyer",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Carl M.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-31",
        "review_rate": 4.9,
        "customer_name": "Kelly Lopez",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Kenneth D.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-09",
        "review_rate": 4.9,
        "customer_name": "Daniel Orr",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Pamela F.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-21",
        "review_rate": 4.8,
        "customer_name": "Jodi Suarez",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Ivan C.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-22",
        "review_rate": 4.8,
        "customer_name": "Shannon Davis",
        "service_type": "Garage Door Installation",
        "tech_name": "Jonathan W.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-17",
        "review_rate": 5.0,
        "customer_name": "Rebecca Sullivan",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Mason S.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-27",
        "review_rate": 5.0,
        "customer_name": "Stephanie Neal",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Kristin A.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-22",
        "review_rate": 5.0,
        "customer_name": "Jose Webb",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Calvin N.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.9,
        "customer_name": "Emily Snyder",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Victor H.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-10",
        "review_rate": 5.0,
        "customer_name": "Martha Young",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Alexis H.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-27",
        "review_rate": 4.9,
        "customer_name": "Greg Wilson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Javier D.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-07",
        "review_rate": 4.9,
        "customer_name": "Catherine Moore",
        "service_type": "Garage Door Repair",
        "tech_name": "Elizabeth W.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-09",
        "review_rate": 5.0,
        "customer_name": "Jonathan Graves",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Bethany C.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-19",
        "review_rate": 5.0,
        "customer_name": "Thomas Arnold",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Kaitlyn C.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-20",
        "review_rate": 4.8,
        "customer_name": "Dorothy Ballard",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Ryan G.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-29",
        "review_rate": 4.9,
        "customer_name": "Christopher Harris",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Michael K.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-16",
        "review_rate": 4.8,
        "customer_name": "Jasmine Foster",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Heather T.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.8,
        "customer_name": "Shawn Taylor",
        "service_type": "Garage Door Repair",
        "tech_name": "Brandy D.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-02",
        "review_rate": 5.0,
        "customer_name": "Cheryl Wiggins",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Timothy W.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-10",
        "review_rate": 4.9,
        "customer_name": "Connie Brooks",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Robert R.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-15",
        "review_rate": 5.0,
        "customer_name": "Scott Bryan",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jeffrey B.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-01",
        "review_rate": 4.9,
        "customer_name": "Billy Rodriguez",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Patrick H.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.9,
        "customer_name": "Shelby Campbell",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Walter H.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-14",
        "review_rate": 4.9,
        "customer_name": "Brian Conway",
        "service_type": "Garage Door Repair",
        "tech_name": "Amanda M.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-26",
        "review_rate": 4.8,
        "customer_name": "Elizabeth Dawson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Angela G.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-25",
        "review_rate": 4.8,
        "customer_name": "Danielle Wallace",
        "service_type": "Garage Door Opener",
        "tech_name": "Manuel D.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-07",
        "review_rate": 4.8,
        "customer_name": "Felicia Salas",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Scott H.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-23",
        "review_rate": 4.9,
        "customer_name": "Richard Johnson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Lisa T.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-10",
        "review_rate": 4.9,
        "customer_name": "Daniel Erickson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jessica N.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-13",
        "review_rate": 4.9,
        "customer_name": "Thomas Craig",
        "service_type": "Garage Door Services",
        "tech_name": "Anne S.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-05",
        "review_rate": 4.8,
        "customer_name": "Crystal Lawrence",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Lacey W.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-02",
        "review_rate": 4.9,
        "customer_name": "Lori Williams",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Marissa F.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-30",
        "review_rate": 4.8,
        "customer_name": "Kristen Richards",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Joann B.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-16",
        "review_rate": 4.8,
        "customer_name": "Beth Roberts",
        "service_type": "Garage Door Services",
        "tech_name": "Emily L.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-10",
        "review_rate": 4.9,
        "customer_name": "Carrie Wilson",
        "service_type": "Garage Door Installation",
        "tech_name": "Christopher Q.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-09",
        "review_rate": 4.9,
        "customer_name": "Kendra Gomez",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Andrew J.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.9,
        "customer_name": "Malik Gutierrez",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Christopher F.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-05",
        "review_rate": 4.9,
        "customer_name": "James Roman",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Gary M.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-16",
        "review_rate": 4.9,
        "customer_name": "Bradley Martinez",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Lisa M.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-06",
        "review_rate": 4.9,
        "customer_name": "Tammy Rosales",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Nicole H.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-23",
        "review_rate": 4.8,
        "customer_name": "Patricia Rios",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Karen A.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-01",
        "review_rate": 4.9,
        "customer_name": "Edwin Johnson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Pamela G.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-31",
        "review_rate": 4.8,
        "customer_name": "Sarah Hernandez",
        "service_type": "Garage Door Installation",
        "tech_name": "Jesse R.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.8,
        "customer_name": "Daniel Greene",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Nicole J.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-15",
        "review_rate": 4.8,
        "customer_name": "Anna Terrell",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Laurie E.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-17",
        "review_rate": 5.0,
        "customer_name": "David Garcia",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jason L.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-24",
        "review_rate": 5.0,
        "customer_name": "James Pierce",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Christy S.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-22",
        "review_rate": 4.8,
        "customer_name": "Krista Levine",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "David S.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-15",
        "review_rate": 5.0,
        "customer_name": "Wanda Jackson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Maria M.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-16",
        "review_rate": 4.8,
        "customer_name": "Shane Lyons",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Donna B.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-16",
        "review_rate": 5.0,
        "customer_name": "Gabriel Rice",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Eric R.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-01",
        "review_rate": 5.0,
        "customer_name": "Debra Williams",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Amy D.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.9,
        "customer_name": "Nathaniel Knapp",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Austin S.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-21",
        "review_rate": 4.9,
        "customer_name": "Kristin Young",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Tracey R.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-10",
        "review_rate": 5.0,
        "customer_name": "Carl Newman",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jeffrey H.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-09-27",
        "review_rate": 4.8,
        "customer_name": "Marc Gallagher",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Dave M.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-15",
        "review_rate": 4.9,
        "customer_name": "Jill Daniel",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Courtney W.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-21",
        "review_rate": 5.0,
        "customer_name": "Cody Gonzalez",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Heather B.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-26",
        "review_rate": 4.9,
        "customer_name": "Jacqueline Hill",
        "service_type": "Garage Door Insulation",
        "tech_name": "Victor O.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-05",
        "review_rate": 4.9,
        "customer_name": "Paul Turner",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Kelly F.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.8,
        "customer_name": "Felicia Thornton",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Kimberly L.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-05",
        "review_rate": 4.8,
        "customer_name": "Ryan Wilson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Barbara M.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-10",
        "review_rate": 4.9,
        "customer_name": "Brian Randolph",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Tracy M.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-07",
        "review_rate": 4.8,
        "customer_name": "Charles Mcintyre",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Colin M.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-19",
        "review_rate": 4.9,
        "customer_name": "Jessica Kennedy",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jessica H.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-08",
        "review_rate": 4.9,
        "customer_name": "Adam Griffith",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Lisa F.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-14",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Lopez DVM",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jared G.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-21",
        "review_rate": 5.0,
        "customer_name": "Jacqueline Rodriguez",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Elizabeth C.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-22",
        "review_rate": 4.9,
        "customer_name": "Jerry Barnes",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jeffrey H.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.9,
        "customer_name": "Michael Jensen",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Kyle H.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-15",
        "review_rate": 4.9,
        "customer_name": "Daniel Dougherty",
        "service_type": "Garage Door Services",
        "tech_name": "Jacob H.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.9,
        "customer_name": "Thomas Clark",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Danielle P.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-07",
        "review_rate": 4.8,
        "customer_name": "Javier Anderson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Steven H.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.9,
        "customer_name": "Travis Martinez",
        "service_type": "Garage Door Off Track",
        "tech_name": "Chelsea C.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-11",
        "review_rate": 4.8,
        "customer_name": "Michael Bentley",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Amber W.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-27",
        "review_rate": 5.0,
        "customer_name": "Aaron Mora",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Grant C.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-13",
        "review_rate": 4.9,
        "customer_name": "Jeanne Davis",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Edward L.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-05",
        "review_rate": 4.8,
        "customer_name": "Casey Santiago",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "John M.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-10",
        "review_rate": 4.9,
        "customer_name": "Shane Schultz",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Lindsey R.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-17",
        "review_rate": 4.9,
        "customer_name": "Adrienne Werner MD",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Steve A.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-01",
        "review_rate": 5.0,
        "customer_name": "Antonio Walker",
        "service_type": "Garage Door Insulation",
        "tech_name": "Susan B.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-09",
        "review_rate": 4.9,
        "customer_name": "Chad Herrera",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Christopher H.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.9,
        "customer_name": "Tracy Marshall",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Richard K.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-15",
        "review_rate": 4.9,
        "customer_name": "Michelle Williams",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Wanda M.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-11",
        "review_rate": 4.9,
        "customer_name": "Thomas Schwartz",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Heather L.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-23",
        "review_rate": 4.9,
        "customer_name": "Joseph Mason",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Matthew J.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-11",
        "review_rate": 5.0,
        "customer_name": "Michael Nixon",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Leslie A.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.9,
        "customer_name": "Sarah Washington",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Sara M.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-18",
        "review_rate": 4.8,
        "customer_name": "Pamela Cooke",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Elizabeth H.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-27",
        "review_rate": 5.0,
        "customer_name": "Joseph Perez",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Kristin H.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-06",
        "review_rate": 4.9,
        "customer_name": "Roger Simmons",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jillian A.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-23",
        "review_rate": 4.9,
        "customer_name": "Erik Flores",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Shelby S.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-21",
        "review_rate": 4.9,
        "customer_name": "Tyler Shannon",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Bailey L.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-05",
        "review_rate": 4.9,
        "customer_name": "Joyce Hawkins",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Elizabeth H.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-06",
        "review_rate": 4.9,
        "customer_name": "Ryan Lewis",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Michael D.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-16",
        "review_rate": 5.0,
        "customer_name": "Timothy Lopez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Candace B.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-10",
        "review_rate": 4.9,
        "customer_name": "Janice Smith",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Christian S.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-22",
        "review_rate": 4.9,
        "customer_name": "Nathaniel Camacho",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Christina D.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-27",
        "review_rate": 5.0,
        "customer_name": "Jennifer Norman",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jennifer F.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-12",
        "review_rate": 4.9,
        "customer_name": "Mr. Marco Kelly",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "James G.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-18",
        "review_rate": 4.9,
        "customer_name": "Shawna Knox",
        "service_type": "Garage Door Services",
        "tech_name": "Jeffrey M.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-07",
        "review_rate": 5.0,
        "customer_name": "Heather Nicholson MD",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Denise W.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-14",
        "review_rate": 4.9,
        "customer_name": "Jamie Roach",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Anthony D.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-11",
        "review_rate": 4.8,
        "customer_name": "Shannon Castro",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Shane L.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-14",
        "review_rate": 5.0,
        "customer_name": "Kimberly Anderson MD",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Victoria W.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-24",
        "review_rate": 4.9,
        "customer_name": "Ryan Ward",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Tanya B.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-25",
        "review_rate": 4.9,
        "customer_name": "Jennifer Brown",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Amanda W.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-28",
        "review_rate": 5.0,
        "customer_name": "Gabriella Robles",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Dennis G.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-08",
        "review_rate": 4.8,
        "customer_name": "Melvin Jones",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Christina H.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-26",
        "review_rate": 4.9,
        "customer_name": "Jacqueline Merritt",
        "service_type": "Garage Door Repair",
        "tech_name": "Dylan M.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-04",
        "review_rate": 4.9,
        "customer_name": "Samantha Bridges",
        "service_type": "Garage Door Insulation",
        "tech_name": "Natasha Z.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-30",
        "review_rate": 5.0,
        "customer_name": "Rodney Clark",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Rachel S.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-13",
        "review_rate": 4.9,
        "customer_name": "Amber Mann",
        "service_type": "Garage Door Opener",
        "tech_name": "Melissa P.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-24",
        "review_rate": 4.8,
        "customer_name": "Kathleen Anderson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Yvonne V.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-21",
        "review_rate": 4.8,
        "customer_name": "Jeffrey Mitchell",
        "service_type": "Garage Door Installation",
        "tech_name": "Michelle L.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-14",
        "review_rate": 4.8,
        "customer_name": "Ashley Hayes",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jeffery S.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-27",
        "review_rate": 5.0,
        "customer_name": "Michael Silva",
        "service_type": "Garage Door Insulation",
        "tech_name": "Todd P.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-08",
        "review_rate": 4.9,
        "customer_name": "Sheri Walker",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Hector W.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-15",
        "review_rate": 4.9,
        "customer_name": "Jasmine Valencia",
        "service_type": "Garage Door Off Track",
        "tech_name": "Kristina B.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-22",
        "review_rate": 4.8,
        "customer_name": "Tony Ibarra",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Matthew J.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-03",
        "review_rate": 4.9,
        "customer_name": "Michael Ramirez",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Trevor S.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-06",
        "review_rate": 4.9,
        "customer_name": "Willie Chavez",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Deborah W.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-25",
        "review_rate": 4.8,
        "customer_name": "Monica Johnston",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Anne D.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-15",
        "review_rate": 4.8,
        "customer_name": "Amy Mcgrath",
        "service_type": "Garage Door Repair",
        "tech_name": "Bradley C.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-13",
        "review_rate": 4.8,
        "customer_name": "Mr. Donald Curtis DVM",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Crystal A.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-19",
        "review_rate": 4.8,
        "customer_name": "Penny Yang",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Linda B.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-19",
        "review_rate": 4.8,
        "customer_name": "Mrs. Gina Swanson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Kyle D.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-07",
        "review_rate": 4.9,
        "customer_name": "Jodi Bird",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Christopher B.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-22",
        "review_rate": 4.9,
        "customer_name": "Sarah Reyes",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Yolanda S.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-27",
        "review_rate": 4.9,
        "customer_name": "Billy Thompson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Nicole M.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-06",
        "review_rate": 5.0,
        "customer_name": "Stacy Rice",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Kari W.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-16",
        "review_rate": 5.0,
        "customer_name": "Jennifer Brown",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Justin M.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-14",
        "review_rate": 4.9,
        "customer_name": "Jose Young",
        "service_type": "Garage Door Repair",
        "tech_name": "Paul P.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-10",
        "review_rate": 5.0,
        "customer_name": "Deanna Mendez",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Nathan F.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.8,
        "customer_name": "Brian King",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Dawn J.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-22",
        "review_rate": 4.8,
        "customer_name": "Jonathan Cruz",
        "service_type": "Garage Door Services",
        "tech_name": "Taylor W.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-09-15",
        "review_rate": 4.8,
        "customer_name": "Debra Smith",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Steven S.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-13",
        "review_rate": 4.9,
        "customer_name": "Julia Savage",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Evelyn W.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-11",
        "review_rate": 5.0,
        "customer_name": "Matthew Gibson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Angela K.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-09",
        "review_rate": 5.0,
        "customer_name": "Candice Wright DVM",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Robert L.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-11",
        "review_rate": 5.0,
        "customer_name": "Jasmine Estrada",
        "service_type": "Garage Door Off Track",
        "tech_name": "Pamela L.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-11",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Ware",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "William W.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-17",
        "review_rate": 4.8,
        "customer_name": "Matthew Wilson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Evelyn G.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-01",
        "review_rate": 4.9,
        "customer_name": "Rachel Frank",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Leslie G.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.9,
        "customer_name": "Pam Todd",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Gene C.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.8,
        "customer_name": "Hannah Ward",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Christian E.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-17",
        "review_rate": 4.9,
        "customer_name": "Jennifer Cooper",
        "service_type": "Garage Door Off Track",
        "tech_name": "Charles S.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.8,
        "customer_name": "Michael Ramirez",
        "service_type": "Garage Door Track Repair",
        "tech_name": "James D.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-20",
        "review_rate": 4.9,
        "customer_name": "Christine Lane",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Peter C.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-16",
        "review_rate": 4.9,
        "customer_name": "Alexis Carpenter",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Frank N.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-27",
        "review_rate": 4.8,
        "customer_name": "Michelle Lopez",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jacqueline S.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-27",
        "review_rate": 4.9,
        "customer_name": "Amanda Bailey",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Benjamin H.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-23",
        "review_rate": 4.8,
        "customer_name": "Jessica Hanson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Michael M.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-14",
        "review_rate": 4.9,
        "customer_name": "Michael Alvarez",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Christian G.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-26",
        "review_rate": 4.9,
        "customer_name": "Jordan Kerr",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Dustin M.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-25",
        "review_rate": 4.9,
        "customer_name": "Heidi Gardner",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "David T.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-14",
        "review_rate": 4.8,
        "customer_name": "Allen Gutierrez",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Mary D.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-08",
        "review_rate": 4.9,
        "customer_name": "Michelle Turner",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Carol M.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-16",
        "review_rate": 5.0,
        "customer_name": "Jacob Gibson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Sydney W.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.9,
        "customer_name": "Robert Martin",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Ryan S.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-18",
        "review_rate": 4.9,
        "customer_name": "Gwendolyn Garcia",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Laurie M.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-12",
        "review_rate": 4.9,
        "customer_name": "Deanna Jacobs",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Theresa G.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-05",
        "review_rate": 4.9,
        "customer_name": "Nathaniel Pena",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Alexander S.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-04",
        "review_rate": 5.0,
        "customer_name": "Beth Galvan",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Justin S.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-25",
        "review_rate": 4.8,
        "customer_name": "Timothy Hanna",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Robert T.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-09",
        "review_rate": 4.8,
        "customer_name": "Katherine Mclean",
        "service_type": "Garage Door Installation",
        "tech_name": "Johnny T.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-25",
        "review_rate": 5.0,
        "customer_name": "Kendra Rodriguez",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Joseph H.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-02-01",
        "review_rate": 5.0,
        "customer_name": "William Roberts",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Gregory B.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-24",
        "review_rate": 5.0,
        "customer_name": "Dalton Smith",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Karen S.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-04",
        "review_rate": 4.8,
        "customer_name": "Melissa Meyers",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jeremy R.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-20",
        "review_rate": 4.9,
        "customer_name": "Maureen Velazquez",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Cynthia M.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-28",
        "review_rate": 4.8,
        "customer_name": "David Reed",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Sandra M.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-01",
        "review_rate": 4.9,
        "customer_name": "Angelica Herrera",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Bryan C.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-03",
        "review_rate": 5.0,
        "customer_name": "Debra Jackson",
        "service_type": "Garage Door Installation",
        "tech_name": "Shannon L.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.8,
        "customer_name": "Wayne Harris",
        "service_type": "Garage Door Repair",
        "tech_name": "Jessica W.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-05",
        "review_rate": 4.8,
        "customer_name": "Joshua Garza",
        "service_type": "Garage Door Services",
        "tech_name": "Larry G.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-09",
        "review_rate": 4.9,
        "customer_name": "Brenda Donaldson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Sarah C.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-25",
        "review_rate": 5.0,
        "customer_name": "Gina Clark",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Steven K.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-11",
        "review_rate": 4.9,
        "customer_name": "Thomas Tran",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Timothy B.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-20",
        "review_rate": 4.9,
        "customer_name": "Brandon Spears",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Anna R.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-09",
        "review_rate": 4.8,
        "customer_name": "Haley Wade",
        "service_type": "Garage Door Services",
        "tech_name": "Steven L.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-03",
        "review_rate": 5.0,
        "customer_name": "Anthony Branch",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jasmine W.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-07",
        "review_rate": 4.8,
        "customer_name": "Robert Carter",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Kimberly G.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-31",
        "review_rate": 4.9,
        "customer_name": "Rebecca Patel",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Nancy P.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-17",
        "review_rate": 4.8,
        "customer_name": "Guy Cruz",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Courtney E.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-25",
        "review_rate": 4.9,
        "customer_name": "Lauren Hernandez",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "James S.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-02-28",
        "review_rate": 4.9,
        "customer_name": "Jesse Johnson",
        "service_type": "Garage Door Opener",
        "tech_name": "Joshua K.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-19",
        "review_rate": 4.9,
        "customer_name": "Alexander Mathews",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Melanie K.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-02-15",
        "review_rate": 4.9,
        "customer_name": "Grace Benson",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Robert D.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-31",
        "review_rate": 4.9,
        "customer_name": "Erica Chen",
        "service_type": "Garage Door Installation",
        "tech_name": "Nicholas S.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-17",
        "review_rate": 4.9,
        "customer_name": "Mark Palmer",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Aaron V.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.8,
        "customer_name": "Jeffrey Miller",
        "service_type": "Garage Door Services",
        "tech_name": "Bryan P.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-01",
        "review_rate": 5.0,
        "customer_name": "Christopher Mcguire",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Grace H.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-17",
        "review_rate": 4.9,
        "customer_name": "Robert Mendez",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Calvin C.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-30",
        "review_rate": 4.8,
        "customer_name": "Jonathan Lee",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jerry A.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-01",
        "review_rate": 5.0,
        "customer_name": "Kimberly Brown",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Phillip M.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-18",
        "review_rate": 4.8,
        "customer_name": "Manuel Smith",
        "service_type": "Garage Door Repair",
        "tech_name": "Benjamin B.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-30",
        "review_rate": 5.0,
        "customer_name": "Joseph Alexander",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Susan H.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-07",
        "review_rate": 5.0,
        "customer_name": "Tammy Flynn",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Aaron J.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-09",
        "review_rate": 4.8,
        "customer_name": "Lauren Gaines",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Paul W.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-21",
        "review_rate": 5.0,
        "customer_name": "Christine Shannon",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Kimberly B.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-29",
        "review_rate": 5.0,
        "customer_name": "Patricia Stanley",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "James S.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-22",
        "review_rate": 5.0,
        "customer_name": "Sheryl Graham",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Stefanie H.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-16",
        "review_rate": 5.0,
        "customer_name": "Kimberly Garcia",
        "service_type": "Garage Door Insulation",
        "tech_name": "James C.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-03",
        "review_rate": 5.0,
        "customer_name": "Jeffrey Conley",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "April R.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-09",
        "review_rate": 4.9,
        "customer_name": "Veronica Boone",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Richard H.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-19",
        "review_rate": 4.8,
        "customer_name": "Diane Frank",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Elizabeth F.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-01",
        "review_rate": 4.9,
        "customer_name": "Eric Williams",
        "service_type": "Garage Door Opener",
        "tech_name": "Victoria P.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-02-08",
        "review_rate": 5.0,
        "customer_name": "Chelsea Morris",
        "service_type": "Garage Door Insulation",
        "tech_name": "Bobby H.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-09",
        "review_rate": 4.9,
        "customer_name": "Scott Brooks",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Lisa P.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-25",
        "review_rate": 4.9,
        "customer_name": "Dr. Jennifer Pineda",
        "service_type": "Garage Door Repair",
        "tech_name": "Tiffany C.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.8,
        "customer_name": "Sean Hatfield",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Kristin R.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-17",
        "review_rate": 4.8,
        "customer_name": "Adam Simon",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Erica K.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-07",
        "review_rate": 4.9,
        "customer_name": "Ashley Bates",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Melissa W.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-17",
        "review_rate": 4.9,
        "customer_name": "Sharon Gomez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Alan W.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-14",
        "review_rate": 5.0,
        "customer_name": "Sandra Hawkins",
        "service_type": "Garage Door Insulation",
        "tech_name": "Eric W.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-20",
        "review_rate": 5.0,
        "customer_name": "Dr. Heather Martinez",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Grace A.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-18",
        "review_rate": 5.0,
        "customer_name": "Bryan Bauer",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Tammy B.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-13",
        "review_rate": 5.0,
        "customer_name": "Mr. Jason Welch",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Sylvia M.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-19",
        "review_rate": 4.9,
        "customer_name": "Katherine Lee",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Matthew G.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-21",
        "review_rate": 4.9,
        "customer_name": "Michael Davis",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Julia M.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-18",
        "review_rate": 4.9,
        "customer_name": "Kristen Cruz",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Luis S.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-18",
        "review_rate": 4.9,
        "customer_name": "Robin Harris",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Barry K.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-12",
        "review_rate": 4.9,
        "customer_name": "Jamie Bolton",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Michael F.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-26",
        "review_rate": 4.9,
        "customer_name": "Courtney Contreras",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Bradley W.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-26",
        "review_rate": 5.0,
        "customer_name": "Jose Schroeder",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Richard H.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.8,
        "customer_name": "James Olson",
        "service_type": "Garage Door Services",
        "tech_name": "Thomas W.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-18",
        "review_rate": 5.0,
        "customer_name": "Jose Williams",
        "service_type": "Garage Door Opener",
        "tech_name": "Steven R.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-05",
        "review_rate": 5.0,
        "customer_name": "Kathryn Jackson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Victoria N.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-22",
        "review_rate": 4.9,
        "customer_name": "Chad Hubbard",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Robert N.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-11",
        "review_rate": 5.0,
        "customer_name": "Mathew Dawson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Anthony M.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-13",
        "review_rate": 4.8,
        "customer_name": "Jacob Reid",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Tiffany Y.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-18",
        "review_rate": 5.0,
        "customer_name": "Donald Elliott",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Pamela Z.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-03",
        "review_rate": 4.9,
        "customer_name": "Brenda Gould",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Lawrence H.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.8,
        "customer_name": "David Lowe",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Marie G.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-17",
        "review_rate": 5.0,
        "customer_name": "Ryan Pope",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Christine N.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-24",
        "review_rate": 4.8,
        "customer_name": "Emily Williams",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Christopher H.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-01",
        "review_rate": 5.0,
        "customer_name": "Peter Young",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Harry C.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-17",
        "review_rate": 5.0,
        "customer_name": "Jeremy Le DVM",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Kristine D.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-10",
        "review_rate": 4.8,
        "customer_name": "Autumn Barrett",
        "service_type": "Garage Door Services",
        "tech_name": "Sophia M.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-15",
        "review_rate": 4.9,
        "customer_name": "John Smith",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Mary B.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-19",
        "review_rate": 4.9,
        "customer_name": "Jonathan Hernandez",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "John C.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-15",
        "review_rate": 4.9,
        "customer_name": "Dennis Doyle",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Vernon J.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-24",
        "review_rate": 4.9,
        "customer_name": "Gregory Young",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Shannon B.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-28",
        "review_rate": 5.0,
        "customer_name": "Dr. Cheyenne Sullivan",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Peter C.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-12",
        "review_rate": 4.9,
        "customer_name": "Thomas Jones",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Ana B.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-20",
        "review_rate": 4.9,
        "customer_name": "Shane Rowe",
        "service_type": "Garage Door Off Track",
        "tech_name": "Richard D.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-21",
        "review_rate": 4.8,
        "customer_name": "Paul Oliver",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Hector V.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-01",
        "review_rate": 4.9,
        "customer_name": "Ruth Burton",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Brandon A.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-15",
        "review_rate": 4.9,
        "customer_name": "Aaron Rocha",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Susan R.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-03",
        "review_rate": 4.9,
        "customer_name": "Marco Smith",
        "service_type": "Garage Door Opener",
        "tech_name": "Danielle M.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-15",
        "review_rate": 4.8,
        "customer_name": "Tracy Mitchell",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Kristen S.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-26",
        "review_rate": 4.9,
        "customer_name": "Brendan Paul",
        "service_type": "Garage Door Services",
        "tech_name": "Michelle S.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-10",
        "review_rate": 4.9,
        "customer_name": "Sandra Villa",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Erica K.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-19",
        "review_rate": 4.8,
        "customer_name": "Laura Gibson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "William P.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-11",
        "review_rate": 4.9,
        "customer_name": "Matthew Thompson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jesse M.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-28",
        "review_rate": 5.0,
        "customer_name": "Summer Curtis",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Ashley R.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-04",
        "review_rate": 5.0,
        "customer_name": "Amy Ponce",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Ana C.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-21",
        "review_rate": 4.9,
        "customer_name": "Katie Frank",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Peter H.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-13",
        "review_rate": 4.9,
        "customer_name": "Carla Silva",
        "service_type": "Garage Door Opener",
        "tech_name": "Jacob C.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-24",
        "review_rate": 4.9,
        "customer_name": "Michael Guzman",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Charles P.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-17",
        "review_rate": 4.9,
        "customer_name": "Erin Davis",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Steve M.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.9,
        "customer_name": "Anna King",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Larry S.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-21",
        "review_rate": 5.0,
        "customer_name": "Brian Patton",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jordan M.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-26",
        "review_rate": 4.9,
        "customer_name": "Robert Potter",
        "service_type": "Garage Door Installation",
        "tech_name": "Charles B.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-08",
        "review_rate": 4.8,
        "customer_name": "Susan Cameron",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Kelly M.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-04",
        "review_rate": 4.8,
        "customer_name": "Brent Campbell",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Sarah M.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-16",
        "review_rate": 4.9,
        "customer_name": "Michael Rice",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Tabitha M.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-25",
        "review_rate": 5.0,
        "customer_name": "Stacey Stewart",
        "service_type": "Garage Door Installation",
        "tech_name": "Joshua B.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-04",
        "review_rate": 4.9,
        "customer_name": "Laurie Carter",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Emma C.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-22",
        "review_rate": 5.0,
        "customer_name": "Courtney Caldwell",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Thomas H.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-12",
        "review_rate": 4.9,
        "customer_name": "William Ford",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Kenneth M.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-09",
        "review_rate": 5.0,
        "customer_name": "Heather Yu MD",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Amanda K.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-22",
        "review_rate": 5.0,
        "customer_name": "Joseph Vazquez",
        "service_type": "Garage Door Insulation",
        "tech_name": "Pamela B.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-08",
        "review_rate": 5.0,
        "customer_name": "Erika Petty",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Michelle G.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-02",
        "review_rate": 5.0,
        "customer_name": "Aaron Ortiz",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "James F.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-22",
        "review_rate": 4.8,
        "customer_name": "Jacob Porter",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jennifer C.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.9,
        "customer_name": "Kenneth Anderson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Patricia G.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-02",
        "review_rate": 5.0,
        "customer_name": "Rhonda Nicholson",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Valerie R.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-27",
        "review_rate": 5.0,
        "customer_name": "Tonya Krause",
        "service_type": "Garage Door Services",
        "tech_name": "David W.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-12",
        "review_rate": 4.9,
        "customer_name": "David Lowery",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Richard W.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-02",
        "review_rate": 5.0,
        "customer_name": "Sarah Aguilar",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Raymond F.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-27",
        "review_rate": 4.9,
        "customer_name": "Michael Archer",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "William J.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-09",
        "review_rate": 5.0,
        "customer_name": "Michael Williams",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Joseph D.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-10",
        "review_rate": 4.8,
        "customer_name": "Teresa Andrews",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Angela F.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-02",
        "review_rate": 4.9,
        "customer_name": "Rebecca Knapp",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Heather P.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-02",
        "review_rate": 4.9,
        "customer_name": "Morgan Cisneros",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Dawn M.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.9,
        "customer_name": "Gerald Bass",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Allen W.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-10",
        "review_rate": 4.8,
        "customer_name": "Terry Thomas",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Kenneth C.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-17",
        "review_rate": 4.9,
        "customer_name": "Mrs. Jessica Olson DVM",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Alan P.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-22",
        "review_rate": 4.9,
        "customer_name": "Tony Crane",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Sally M.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-23",
        "review_rate": 5.0,
        "customer_name": "Mary Mitchell",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Todd M.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-18",
        "review_rate": 4.9,
        "customer_name": "Lauren Adkins",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Justin J.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-01",
        "review_rate": 5.0,
        "customer_name": "Nicholas Mccarthy",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Patrick R.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-09-28",
        "review_rate": 4.9,
        "customer_name": "Michaela Rodgers",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Michelle M.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.9,
        "customer_name": "Robin Wall",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "James J.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-15",
        "review_rate": 4.9,
        "customer_name": "Joseph Marks",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Corey J.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-03",
        "review_rate": 5.0,
        "customer_name": "Jonathan Blackburn",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Bryan D.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-31",
        "review_rate": 4.9,
        "customer_name": "Rebecca Williams",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Lisa D.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-09-30",
        "review_rate": 4.8,
        "customer_name": "James Jenkins",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Lynn M.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-05",
        "review_rate": 4.9,
        "customer_name": "Mrs. Brandi Aguirre",
        "service_type": "Garage Door Insulation",
        "tech_name": "Shannon D.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-15",
        "review_rate": 4.9,
        "customer_name": "Tonya Peck",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Michael M.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-06",
        "review_rate": 5.0,
        "customer_name": "Charles Koch",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jessica J.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-12",
        "review_rate": 4.9,
        "customer_name": "Susan Horton",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Katherine S.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.9,
        "customer_name": "David May",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Scott B.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-16",
        "review_rate": 4.8,
        "customer_name": "Jonathon Daniels",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Christopher W.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-10",
        "review_rate": 4.8,
        "customer_name": "Tiffany Perez",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "John W.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.8,
        "customer_name": "Shelby Garcia",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Julie Z.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-22",
        "review_rate": 4.8,
        "customer_name": "Tom Smith",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Michelle M.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-27",
        "review_rate": 5.0,
        "customer_name": "Nicholas Wright",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Stacy A.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-08",
        "review_rate": 4.9,
        "customer_name": "Zachary Kelly",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Kathleen W.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-01",
        "review_rate": 4.9,
        "customer_name": "Angela Harper",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Adriana F.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-11",
        "review_rate": 4.9,
        "customer_name": "Danielle Vega",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Gabriella B.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-10",
        "review_rate": 4.9,
        "customer_name": "Michael Hernandez",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Kristi J.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-30",
        "review_rate": 4.9,
        "customer_name": "Jennifer Bennett",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Stephen D.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-14",
        "review_rate": 5.0,
        "customer_name": "Dawn Hunt",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jaclyn C.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-04",
        "review_rate": 5.0,
        "customer_name": "Robert Wood",
        "service_type": "Garage Door Repair",
        "tech_name": "Daniel D.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-23",
        "review_rate": 5.0,
        "customer_name": "Scott Dudley",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Grant P.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.9,
        "customer_name": "Michael Vance",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jordan B.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-20",
        "review_rate": 4.8,
        "customer_name": "Nicole May",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Katherine R.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-21",
        "review_rate": 5.0,
        "customer_name": "Richard Berry",
        "service_type": "Garage Door Opener",
        "tech_name": "John G.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-15",
        "review_rate": 4.9,
        "customer_name": "Sylvia Perez",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Kevin M.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-26",
        "review_rate": 4.8,
        "customer_name": "Scott Oliver",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Maria B.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-04",
        "review_rate": 5.0,
        "customer_name": "Linda Phillips",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Mary H.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.8,
        "customer_name": "Sherri Salas",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Barbara F.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-16",
        "review_rate": 4.9,
        "customer_name": "Diane Dunn",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Cristina G.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-13",
        "review_rate": 5.0,
        "customer_name": "Angela Rich",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jason H.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-21",
        "review_rate": 5.0,
        "customer_name": "Barry Fowler",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Monica W.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-11",
        "review_rate": 4.8,
        "customer_name": "Brian Tyler",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Patrick W.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-02",
        "review_rate": 4.9,
        "customer_name": "Emily Paul",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Nathan D.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-31",
        "review_rate": 4.9,
        "customer_name": "Adam Robinson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Matthew R.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-02",
        "review_rate": 4.9,
        "customer_name": "Julie Clarke",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Mark M.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-23",
        "review_rate": 4.9,
        "customer_name": "Jessica Stout",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Brian M.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.9,
        "customer_name": "Rebecca Dennis",
        "service_type": "Garage Door Repair",
        "tech_name": "Patrick F.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-13",
        "review_rate": 4.9,
        "customer_name": "Alan Anderson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Kristin K.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-04",
        "review_rate": 5.0,
        "customer_name": "Arthur Duffy",
        "service_type": "Garage Door Opener",
        "tech_name": "Nicole F.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-29",
        "review_rate": 4.9,
        "customer_name": "Alexander Bolton",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Daniel M.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-09-21",
        "review_rate": 4.9,
        "customer_name": "Jennifer Jordan",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Joe T.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-07",
        "review_rate": 4.9,
        "customer_name": "Scott Ramsey",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Robert B.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-01",
        "review_rate": 4.9,
        "customer_name": "Sean Watson",
        "service_type": "Garage Door Opener",
        "tech_name": "Gerald H.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-12",
        "review_rate": 4.9,
        "customer_name": "Lori Martinez",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Barbara W.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-18",
        "review_rate": 4.8,
        "customer_name": "Jonathan Miller",
        "service_type": "Garage Door Off Track",
        "tech_name": "Barbara S.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-07",
        "review_rate": 4.9,
        "customer_name": "Jennifer Hernandez",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Sophia E.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-19",
        "review_rate": 4.9,
        "customer_name": "Darrell Colon",
        "service_type": "Garage Door Off Track",
        "tech_name": "Susan M.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-01",
        "review_rate": 4.8,
        "customer_name": "Travis Walker MD",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Margaret S.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-20",
        "review_rate": 4.9,
        "customer_name": "Michael Diaz",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "David R.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-19",
        "review_rate": 4.9,
        "customer_name": "Jonathan Wilson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Toni H.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-09-15",
        "review_rate": 4.9,
        "customer_name": "Angela Kim",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Kimberly J.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-27",
        "review_rate": 5.0,
        "customer_name": "Jonathan Romero",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Brenda W.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-26",
        "review_rate": 4.9,
        "customer_name": "Joseph Dougherty",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Kim C.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-12",
        "review_rate": 4.9,
        "customer_name": "Sandra Watson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Andrea D.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-26",
        "review_rate": 4.8,
        "customer_name": "Patrick Stewart",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Robert G.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-27",
        "review_rate": 4.9,
        "customer_name": "Alyssa Henderson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Brenda H.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-19",
        "review_rate": 4.9,
        "customer_name": "Alisha Aguilar",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Shawn P.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-14",
        "review_rate": 4.8,
        "customer_name": "Brenda Rogers",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Patrick S.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-10",
        "review_rate": 4.9,
        "customer_name": "Austin Erickson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Susan W.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-22",
        "review_rate": 4.9,
        "customer_name": "Renee Santiago",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Brandon R.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-29",
        "review_rate": 4.8,
        "customer_name": "Carolyn French",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Michelle C.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-13",
        "review_rate": 4.9,
        "customer_name": "Heather Bowen",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jamie G.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-22",
        "review_rate": 5.0,
        "customer_name": "Dave Mclean",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Troy B.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-08",
        "review_rate": 5.0,
        "customer_name": "Emily Harris",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jennifer L.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-09",
        "review_rate": 4.9,
        "customer_name": "Jennifer Lane",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Kimberly H.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-25",
        "review_rate": 4.9,
        "customer_name": "Katrina Miller",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Mallory J.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-09-24",
        "review_rate": 5.0,
        "customer_name": "Donald Alvarez",
        "service_type": "Garage Door Opener",
        "tech_name": "Jonathan P.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-07",
        "review_rate": 5.0,
        "customer_name": "Andre Brown",
        "service_type": "Garage Door Opener",
        "tech_name": "David A.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-05",
        "review_rate": 5.0,
        "customer_name": "William Jackson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Luis H.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-18",
        "review_rate": 4.8,
        "customer_name": "Jesse Bass",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jason M.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-08",
        "review_rate": 5.0,
        "customer_name": "Pamela Smith",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Alyssa G.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-08",
        "review_rate": 5.0,
        "customer_name": "Alfred Acosta",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Matthew M.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-15",
        "review_rate": 4.8,
        "customer_name": "Peter Simon",
        "service_type": "Garage Door Repair",
        "tech_name": "Juan B.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.8,
        "customer_name": "Connie Franklin",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Patrick W.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-18",
        "review_rate": 4.9,
        "customer_name": "Patricia Clark DDS",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Michael M.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-29",
        "review_rate": 4.8,
        "customer_name": "Alexandria Alvarez",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "William A.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-03",
        "review_rate": 4.9,
        "customer_name": "Stephanie Mckenzie",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Deanna P.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-08",
        "review_rate": 5.0,
        "customer_name": "Gregory Green",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Michael M.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-19",
        "review_rate": 5.0,
        "customer_name": "Aaron Walker PhD",
        "service_type": "Garage Door Opener",
        "tech_name": "Sydney B.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-15",
        "review_rate": 4.8,
        "customer_name": "Patrick Wiggins",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Diane B.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-13",
        "review_rate": 4.9,
        "customer_name": "Philip Williams",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Angela W.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-14",
        "review_rate": 4.9,
        "customer_name": "William Williams",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Michael S.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-30",
        "review_rate": 5.0,
        "customer_name": "Zachary Clark",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Angela T.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-27",
        "review_rate": 5.0,
        "customer_name": "Alejandro Taylor",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jennifer A.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-20",
        "review_rate": 4.9,
        "customer_name": "Lisa Lucero",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Bryan G.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.9,
        "customer_name": "Sarah Parker",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "James C.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-12",
        "review_rate": 4.9,
        "customer_name": "Sarah Phillips",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "James H.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.8,
        "customer_name": "Gregory Smith",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Steven D.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-06",
        "review_rate": 4.9,
        "customer_name": "Patrick Kelly",
        "service_type": "Garage Door Services",
        "tech_name": "Walter P.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-04",
        "review_rate": 5.0,
        "customer_name": "William Roberts",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Christopher N.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-06",
        "review_rate": 4.8,
        "customer_name": "Heather Brown",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Anthony B.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.9,
        "customer_name": "Vincent Ross",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Kelly A.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-09-06",
        "review_rate": 4.8,
        "customer_name": "Michael Nichols",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Christopher M.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-05",
        "review_rate": 4.9,
        "customer_name": "Julia Payne",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Shawn C.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-27",
        "review_rate": 5.0,
        "customer_name": "Michael Wood",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Leslie G.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-02",
        "review_rate": 4.8,
        "customer_name": "Brian Simpson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jeffrey J.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-09",
        "review_rate": 4.9,
        "customer_name": "Jordan Price",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Mary C.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.9,
        "customer_name": "Michael Williamson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "William A.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-23",
        "review_rate": 4.9,
        "customer_name": "Stephanie Collins",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Victor M.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-25",
        "review_rate": 4.8,
        "customer_name": "Dominique Sandoval",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jennifer B.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-01",
        "review_rate": 4.9,
        "customer_name": "Thomas Miller",
        "service_type": "Garage Door Off Track",
        "tech_name": "Alexandra B.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-24",
        "review_rate": 4.9,
        "customer_name": "Samuel Hanson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Robert D.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-15",
        "review_rate": 4.8,
        "customer_name": "Melinda White",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Lucas R.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-03",
        "review_rate": 5.0,
        "customer_name": "Melissa Young",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "John P.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-09-25",
        "review_rate": 4.9,
        "customer_name": "Chad Bishop",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Mary F.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-27",
        "review_rate": 4.8,
        "customer_name": "Ronald Fisher",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Jerry F.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-27",
        "review_rate": 5.0,
        "customer_name": "Crystal Schneider",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Miguel W.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-19",
        "review_rate": 4.9,
        "customer_name": "Brett Mcgee",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Pam V.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-17",
        "review_rate": 5.0,
        "customer_name": "Roger Moore",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "David B.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-23",
        "review_rate": 4.8,
        "customer_name": "Matthew Arnold",
        "service_type": "Garage Door Repair",
        "tech_name": "Stephanie S.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-05",
        "review_rate": 5.0,
        "customer_name": "James Kelly",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Willie O.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-23",
        "review_rate": 4.9,
        "customer_name": "Stephanie White",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Cheyenne P.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-20",
        "review_rate": 4.8,
        "customer_name": "Ruben Hayes",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Robert L.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-09",
        "review_rate": 4.9,
        "customer_name": "Scott Mcintyre",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jason M.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-10",
        "review_rate": 4.9,
        "customer_name": "Calvin Sanchez",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jessica T.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-30",
        "review_rate": 4.9,
        "customer_name": "Robert Coleman",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Andrew R.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-03",
        "review_rate": 4.8,
        "customer_name": "Shawn Guerrero",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Paul B.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-24",
        "review_rate": 4.8,
        "customer_name": "Eric Dawson",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Mary H.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-13",
        "review_rate": 4.8,
        "customer_name": "Dr. Anita Jackson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Brian P.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-13",
        "review_rate": 4.9,
        "customer_name": "Jennifer Eaton",
        "service_type": "Garage Door Opener",
        "tech_name": "Brian H.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-28",
        "review_rate": 4.9,
        "customer_name": "Andrea Moore",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Joshua G.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-08",
        "review_rate": 4.8,
        "customer_name": "Priscilla Prince",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Danielle M.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-03",
        "review_rate": 4.9,
        "customer_name": "Dr. Travis Sutton DDS",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Beth M.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-18",
        "review_rate": 5.0,
        "customer_name": "Elizabeth Higgins",
        "service_type": "Garage Door Opener",
        "tech_name": "Erin L.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-13",
        "review_rate": 4.8,
        "customer_name": "Tracy Hernandez",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Sarah R.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-24",
        "review_rate": 4.8,
        "customer_name": "Michael Tate",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Julian T.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-19",
        "review_rate": 4.8,
        "customer_name": "Christopher Myers",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Grace F.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-13",
        "review_rate": 5.0,
        "customer_name": "Brent Jones",
        "service_type": "Garage Door Opener",
        "tech_name": "Danielle H.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.8,
        "customer_name": "Matthew Guzman",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Lindsey W.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-20",
        "review_rate": 5.0,
        "customer_name": "Patricia Gonzalez",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Samantha C.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-10",
        "review_rate": 4.9,
        "customer_name": "John Robertson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Angela G.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-22",
        "review_rate": 4.9,
        "customer_name": "Nicole Ortiz",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "George H.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-03",
        "review_rate": 4.8,
        "customer_name": "Christopher Lucas",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Gary B.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-07",
        "review_rate": 4.9,
        "customer_name": "Kristi Burnett",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jose S.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-12",
        "review_rate": 5.0,
        "customer_name": "Andrea Arellano",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "James W.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-01",
        "review_rate": 4.9,
        "customer_name": "Tiffany Moss",
        "service_type": "Garage Door Opener",
        "tech_name": "Alicia K.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-30",
        "review_rate": 5.0,
        "customer_name": "Steven Brown",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Alexis H.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-31",
        "review_rate": 5.0,
        "customer_name": "Jennifer Morrison",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Paula D.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-08",
        "review_rate": 4.8,
        "customer_name": "David West",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Donna C.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-31",
        "review_rate": 5.0,
        "customer_name": "Emily Rodriguez",
        "service_type": "Custom Garage Door Design",
        "tech_name": "James K.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-25",
        "review_rate": 4.9,
        "customer_name": "Laura Atkins",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "David C.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-27",
        "review_rate": 5.0,
        "customer_name": "Lisa Walters",
        "service_type": "Garage Door Repair",
        "tech_name": "Amanda H.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-29",
        "review_rate": 4.9,
        "customer_name": "Andrea Trujillo",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Adam E.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-07",
        "review_rate": 4.8,
        "customer_name": "Vanessa Davenport",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Tiffany A.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-19",
        "review_rate": 5.0,
        "customer_name": "Christopher Rose",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jennifer V.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-16",
        "review_rate": 4.9,
        "customer_name": "Mary Reed",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Lisa M.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-25",
        "review_rate": 5.0,
        "customer_name": "Kimberly Lane",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Alicia L.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-27",
        "review_rate": 4.9,
        "customer_name": "Ralph Moore",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Michael M.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-30",
        "review_rate": 4.9,
        "customer_name": "Thomas Ewing",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Mary C.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-14",
        "review_rate": 5.0,
        "customer_name": "Mrs. Sheri Lawrence",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Danielle K.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-15",
        "review_rate": 4.9,
        "customer_name": "Tiffany Cruz",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Matthew F.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-09",
        "review_rate": 4.9,
        "customer_name": "Michael Erickson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Stephen D.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-04",
        "review_rate": 5.0,
        "customer_name": "Joshua Bell",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Ian W.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-22",
        "review_rate": 4.9,
        "customer_name": "Jacob Howard",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Matthew M.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-24",
        "review_rate": 5.0,
        "customer_name": "Gregory Garcia",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jerry W.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-27",
        "review_rate": 5.0,
        "customer_name": "Jennifer Rodriguez",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Fred S.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-05",
        "review_rate": 4.9,
        "customer_name": "Jeffery Delgado",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Charlene O.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.9,
        "customer_name": "Mark Adams",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Richard H.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-08",
        "review_rate": 5.0,
        "customer_name": "Cynthia Hunter",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "William F.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-10",
        "review_rate": 4.8,
        "customer_name": "Wendy Douglas",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Clayton T.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-01",
        "review_rate": 4.8,
        "customer_name": "Peter Powers",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jeffery F.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-02",
        "review_rate": 5.0,
        "customer_name": "Tammie Armstrong",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jose O.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-13",
        "review_rate": 4.8,
        "customer_name": "Lucas Baker",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Adam W.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-10",
        "review_rate": 5.0,
        "customer_name": "Kimberly Miller",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Stephanie P.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.9,
        "customer_name": "Jacob Allen",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Jason G.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-29",
        "review_rate": 4.9,
        "customer_name": "Jamie Galvan",
        "service_type": "Garage Door Maintenance",
        "tech_name": "John C.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-02",
        "review_rate": 4.9,
        "customer_name": "Brett Mitchell",
        "service_type": "Garage Door Services",
        "tech_name": "Stephen C.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-30",
        "review_rate": 5.0,
        "customer_name": "Renee Long",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Nicholas W.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-14",
        "review_rate": 4.9,
        "customer_name": "Eugene Wilson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Ernest L.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-30",
        "review_rate": 5.0,
        "customer_name": "Matthew Carson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Joshua H.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.9,
        "customer_name": "David Simpson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Veronica L.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.8,
        "customer_name": "Michael Castillo",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Calvin M.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-10",
        "review_rate": 4.9,
        "customer_name": "Ashley Saunders",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Gerald W.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-09-27",
        "review_rate": 5.0,
        "customer_name": "Roberto Adams",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Yolanda P.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-17",
        "review_rate": 4.8,
        "customer_name": "Zachary Butler",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Robin P.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-29",
        "review_rate": 4.8,
        "customer_name": "Kathy Mclaughlin",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Anne M.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-09-14",
        "review_rate": 5.0,
        "customer_name": "Mackenzie Moses",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Alex T.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-06",
        "review_rate": 5.0,
        "customer_name": "Laura Jennings",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Catherine J.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.9,
        "customer_name": "Keith Alexander",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Paula M.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.9,
        "customer_name": "Margaret Jones",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Megan C.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-13",
        "review_rate": 4.8,
        "customer_name": "George Cardenas",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Nicholas W.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-06",
        "review_rate": 4.9,
        "customer_name": "Christopher Tucker",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Shelly S.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-09-20",
        "review_rate": 5.0,
        "customer_name": "Christopher Dixon",
        "service_type": "Garage Door Opener",
        "tech_name": "Jonathan P.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-25",
        "review_rate": 4.8,
        "customer_name": "Mr. Andrew Sanchez Jr.",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Michael W.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-10",
        "review_rate": 4.9,
        "customer_name": "Christopher Jenkins",
        "service_type": "Garage Door Off Track",
        "tech_name": "Kathy S.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-12",
        "review_rate": 4.9,
        "customer_name": "Lauren Obrien",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Kathy K.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-03",
        "review_rate": 4.8,
        "customer_name": "Jennifer Barker",
        "service_type": "Garage Door Repair",
        "tech_name": "Brian W.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-10",
        "review_rate": 4.9,
        "customer_name": "Stephen Jones",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Kristen S.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.9,
        "customer_name": "Monica Martinez",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Kimberly P.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-09-06",
        "review_rate": 4.9,
        "customer_name": "Barbara Coffey",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Kenneth W.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-17",
        "review_rate": 4.8,
        "customer_name": "Maria Adams",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Rachel M.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.9,
        "customer_name": "Frank Sanders",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Amy H.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-19",
        "review_rate": 4.9,
        "customer_name": "Kelsey Velez",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Benjamin L.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-13",
        "review_rate": 4.8,
        "customer_name": "William Zuniga",
        "service_type": "Garage Door Services",
        "tech_name": "Justin L.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-09-06",
        "review_rate": 4.8,
        "customer_name": "Amy Wilson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Bradley H.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-19",
        "review_rate": 4.9,
        "customer_name": "Heather Humphrey",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Renee W.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-10",
        "review_rate": 4.9,
        "customer_name": "Theresa Hamilton",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "April G.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.9,
        "customer_name": "Donald Jackson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Matthew S.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-26",
        "review_rate": 4.9,
        "customer_name": "Anthony Wolfe",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jose W.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-14",
        "review_rate": 4.8,
        "customer_name": "Phillip Powell",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Michele B.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-23",
        "review_rate": 4.9,
        "customer_name": "Grant Hudson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Natalie G.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-05",
        "review_rate": 5.0,
        "customer_name": "Kelsey Rhodes",
        "service_type": "Garage Door Off Track",
        "tech_name": "Samuel C.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-05",
        "review_rate": 4.9,
        "customer_name": "Linda Roberts",
        "service_type": "Garage Door Services",
        "tech_name": "Lacey L.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-08",
        "review_rate": 5.0,
        "customer_name": "Nicholas Jones",
        "service_type": "Garage Door Off Track",
        "tech_name": "Devon D.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.9,
        "customer_name": "Ashley Bryan",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Luis W.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-05",
        "review_rate": 4.8,
        "customer_name": "Jesse Cruz",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Alisha W.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-12",
        "review_rate": 4.8,
        "customer_name": "Lori Peterson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Erica B.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-20",
        "review_rate": 4.8,
        "customer_name": "Fernando Mayer",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Anthony B.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-10",
        "review_rate": 5.0,
        "customer_name": "William Fuentes",
        "service_type": "Garage Door Opener",
        "tech_name": "Heidi M.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-30",
        "review_rate": 4.8,
        "customer_name": "Julia Lester",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Robert O.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.9,
        "customer_name": "Monica Harris",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Andrew F.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-12",
        "review_rate": 5.0,
        "customer_name": "Amanda Alexander",
        "service_type": "Garage Door Services",
        "tech_name": "Kelsey D.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-16",
        "review_rate": 4.9,
        "customer_name": "Derek Carrillo",
        "service_type": "Garage Door Installation",
        "tech_name": "Jessica T.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-13",
        "review_rate": 4.9,
        "customer_name": "Carla Hernandez",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Michael Q.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-27",
        "review_rate": 5.0,
        "customer_name": "Angela Stein",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Anthony S.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-19",
        "review_rate": 4.8,
        "customer_name": "Sandra Valdez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "William T.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-17",
        "review_rate": 5.0,
        "customer_name": "Daniel Holden",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Kimberly J.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-05",
        "review_rate": 4.8,
        "customer_name": "Olivia Miller",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Andrea F.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-19",
        "review_rate": 4.9,
        "customer_name": "Karen Foster",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "James S.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-10",
        "review_rate": 4.9,
        "customer_name": "Kevin Ward",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Teresa H.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-11",
        "review_rate": 5.0,
        "customer_name": "Christopher Haas",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Richard T.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-13",
        "review_rate": 5.0,
        "customer_name": "Ryan Bright",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Heidi B.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-31",
        "review_rate": 4.9,
        "customer_name": "Lori Jones",
        "service_type": "Garage Door Opener",
        "tech_name": "Daniel W.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-10",
        "review_rate": 5.0,
        "customer_name": "Kyle Ford",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Catherine W.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-11",
        "review_rate": 5.0,
        "customer_name": "Scott Russell",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Lucas O.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-21",
        "review_rate": 4.9,
        "customer_name": "Jordan Frazier",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "John A.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-23",
        "review_rate": 5.0,
        "customer_name": "Joseph Sloan",
        "service_type": "Garage Door Services",
        "tech_name": "Rodney W.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-07",
        "review_rate": 4.9,
        "customer_name": "Kevin Garcia",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Marcus C.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-16",
        "review_rate": 5.0,
        "customer_name": "Douglas May",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Tony K.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-18",
        "review_rate": 5.0,
        "customer_name": "Jessica Thomas",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Paul N.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-31",
        "review_rate": 4.9,
        "customer_name": "Ashley Jefferson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jeffrey H.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-17",
        "review_rate": 4.8,
        "customer_name": "Jennifer Garcia",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "George Y.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-09-14",
        "review_rate": 4.8,
        "customer_name": "James Clark",
        "service_type": "Garage Door Services",
        "tech_name": "Melissa W.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-24",
        "review_rate": 4.9,
        "customer_name": "Lisa Webb",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Lance R.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-20",
        "review_rate": 5.0,
        "customer_name": "Meredith Hill",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Thomas J.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-26",
        "review_rate": 5.0,
        "customer_name": "Frederick Grant",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Kimberly C.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.9,
        "customer_name": "Michael Ramirez",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Amanda H.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.9,
        "customer_name": "Mr. Thomas Singh",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Mandy L.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.8,
        "customer_name": "Stephanie Savage",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Charles J.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-10",
        "review_rate": 5.0,
        "customer_name": "Dustin Hall",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Timothy H.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-28",
        "review_rate": 4.8,
        "customer_name": "Chad Pierce",
        "service_type": "Garage Door Insulation",
        "tech_name": "James P.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-06",
        "review_rate": 4.9,
        "customer_name": "Joseph West",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Derek L.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-04",
        "review_rate": 4.9,
        "customer_name": "Jordan Terry",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Sarah P.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-04",
        "review_rate": 4.9,
        "customer_name": "Veronica Turner",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "David H.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-29",
        "review_rate": 4.9,
        "customer_name": "Kent Holloway",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Joy A.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-06",
        "review_rate": 5.0,
        "customer_name": "Kimberly Chase",
        "service_type": "Garage Door Services",
        "tech_name": "Deanna D.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-09",
        "review_rate": 5.0,
        "customer_name": "Carl Rodriguez",
        "service_type": "Garage Door Installation",
        "tech_name": "Robert M.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-24",
        "review_rate": 4.9,
        "customer_name": "Julie Adams",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "James G.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.9,
        "customer_name": "Kiara Mathis",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Stephanie R.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-04",
        "review_rate": 4.9,
        "customer_name": "Miss Gwendolyn Stokes",
        "service_type": "Garage Door Off Track",
        "tech_name": "Alexander J.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-21",
        "review_rate": 5.0,
        "customer_name": "Justin Collins",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Mark N.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-16",
        "review_rate": 4.9,
        "customer_name": "Michael Smith",
        "service_type": "Garage Door Insulation",
        "tech_name": "Kevin R.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-04",
        "review_rate": 4.9,
        "customer_name": "Jason Hunter",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Ryan C.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-21",
        "review_rate": 5.0,
        "customer_name": "Laura Norman",
        "service_type": "Garage Door Insulation",
        "tech_name": "Julie G.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-21",
        "review_rate": 5.0,
        "customer_name": "Norman Howell",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Clayton H.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.9,
        "customer_name": "Laura Fields",
        "service_type": "Garage Door Insulation",
        "tech_name": "Patricia W.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-01",
        "review_rate": 5.0,
        "customer_name": "Michael Thomas",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Michael F.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-15",
        "review_rate": 4.9,
        "customer_name": "Nicole Andrade",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Julie N.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-03",
        "review_rate": 4.8,
        "customer_name": "Sonya Lopez",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Nicholas Z.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.9,
        "customer_name": "Alisha Peters",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Tammie H.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-24",
        "review_rate": 4.9,
        "customer_name": "April Kramer",
        "service_type": "Garage Door Repair",
        "tech_name": "Heather Y.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-22",
        "review_rate": 4.9,
        "customer_name": "Alyssa Wilson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Christopher A.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-03",
        "review_rate": 4.9,
        "customer_name": "Ryan Howell",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Steven W.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-03",
        "review_rate": 4.9,
        "customer_name": "Kylie Jackson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Brent W.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-12",
        "review_rate": 4.9,
        "customer_name": "Gregory Perez",
        "service_type": "Garage Door Installation",
        "tech_name": "Martha M.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-09-30",
        "review_rate": 5.0,
        "customer_name": "Gregory Berry",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Michael B.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-30",
        "review_rate": 4.8,
        "customer_name": "Christopher Rogers",
        "service_type": "Garage Door Repair",
        "tech_name": "Elizabeth A.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-09-10",
        "review_rate": 4.9,
        "customer_name": "Wendy Reyes",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Marissa W.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-08",
        "review_rate": 4.9,
        "customer_name": "David Gardner",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Donna M.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-26",
        "review_rate": 5.0,
        "customer_name": "Tara George",
        "service_type": "Garage Door Off Track",
        "tech_name": "Tanner M.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-16",
        "review_rate": 5.0,
        "customer_name": "James Schneider",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Kimberly O.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-20",
        "review_rate": 4.9,
        "customer_name": "Colton Evans",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Melissa T.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.9,
        "customer_name": "Angela Rodriguez",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Christopher C.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-16",
        "review_rate": 5.0,
        "customer_name": "David Lowe",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Timothy S.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-30",
        "review_rate": 5.0,
        "customer_name": "Carlos Brown",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Shelly B.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-20",
        "review_rate": 4.8,
        "customer_name": "David Diaz",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Mary G.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-01",
        "review_rate": 4.9,
        "customer_name": "Christopher Taylor",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Glenn M.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-27",
        "review_rate": 4.9,
        "customer_name": "Jennifer Brown",
        "service_type": "Garage Door Repair",
        "tech_name": "Richard H.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-05",
        "review_rate": 4.8,
        "customer_name": "Nicholas Conrad",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Roberto C.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-14",
        "review_rate": 4.8,
        "customer_name": "Lawrence Garcia",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Shane C.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-17",
        "review_rate": 5.0,
        "customer_name": "Kimberly Stone",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Barbara B.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-04",
        "review_rate": 5.0,
        "customer_name": "Kyle Middleton",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Heather M.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-06",
        "review_rate": 4.8,
        "customer_name": "Diana Harmon",
        "service_type": "Garage Door Opener",
        "tech_name": "Robin G.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-09-01",
        "review_rate": 4.9,
        "customer_name": "Karen Weeks",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Isaiah B.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-23",
        "review_rate": 5.0,
        "customer_name": "Cindy Gray",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Laura F.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-18",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Kelly",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jasmine C.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-04",
        "review_rate": 4.8,
        "customer_name": "Nathan Summers",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Brad R.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-01",
        "review_rate": 4.8,
        "customer_name": "Andrew Leach",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Annette M.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-30",
        "review_rate": 5.0,
        "customer_name": "Julie Miller",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Crystal R.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-16",
        "review_rate": 4.8,
        "customer_name": "Michael Diaz",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jerry W.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.9,
        "customer_name": "Robert Williams",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Angela J.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-09",
        "review_rate": 5.0,
        "customer_name": "Richard Warren",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Stephanie G.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-16",
        "review_rate": 4.9,
        "customer_name": "Mark Rogers",
        "service_type": "Garage Door Track Repair",
        "tech_name": "James T.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-22",
        "review_rate": 4.9,
        "customer_name": "Linda Mitchell",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Todd W.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-07",
        "review_rate": 5.0,
        "customer_name": "Rebecca Webster",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Susan B.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-20",
        "review_rate": 4.9,
        "customer_name": "Matthew Davenport",
        "service_type": "Garage Door Off Track",
        "tech_name": "James P.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-27",
        "review_rate": 4.9,
        "customer_name": "Kevin Alvarado",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "William L.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-29",
        "review_rate": 4.9,
        "customer_name": "Sheila Bates",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Katrina R.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-09-27",
        "review_rate": 5.0,
        "customer_name": "Tricia Newman",
        "service_type": "Garage Door Insulation",
        "tech_name": "Valerie J.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-06",
        "review_rate": 5.0,
        "customer_name": "Lisa Moore",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Brian F.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-05",
        "review_rate": 5.0,
        "customer_name": "Anthony Holmes",
        "service_type": "Garage Door Services",
        "tech_name": "Jessica O.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-06",
        "review_rate": 5.0,
        "customer_name": "Matthew Garcia",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Melissa B.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-26",
        "review_rate": 4.8,
        "customer_name": "Paul Mckay",
        "service_type": "Garage Door Opener",
        "tech_name": "Rachel J.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-07",
        "review_rate": 5.0,
        "customer_name": "Craig Rodgers",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Melinda M.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.9,
        "customer_name": "Kathryn Valencia",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Nicole W.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-12",
        "review_rate": 5.0,
        "customer_name": "Todd Davis",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Brittany C.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-19",
        "review_rate": 4.8,
        "customer_name": "Patrick Cook",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Joseph H.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-31",
        "review_rate": 4.8,
        "customer_name": "Joshua Johnson",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Alexis J.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-17",
        "review_rate": 5.0,
        "customer_name": "Earl Burton",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Brendan B.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-31",
        "review_rate": 4.9,
        "customer_name": "Michael Cobb",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Christian W.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.9,
        "customer_name": "Nathan Mitchell",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Molly H.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-31",
        "review_rate": 4.9,
        "customer_name": "Amber Stark",
        "service_type": "Garage Door Opener",
        "tech_name": "Brittany G.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-17",
        "review_rate": 4.8,
        "customer_name": "Kimberly Petersen",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Tracie C.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-09-03",
        "review_rate": 4.9,
        "customer_name": "Sean Robinson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Keith B.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-29",
        "review_rate": 5.0,
        "customer_name": "Kari Holloway",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Rebecca F.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-03",
        "review_rate": 4.9,
        "customer_name": "Shannon Brown",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Cory R.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-22",
        "review_rate": 4.9,
        "customer_name": "Jessica Perkins",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jennifer S.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-28",
        "review_rate": 4.9,
        "customer_name": "Adam Gonzalez",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Monique P.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-04",
        "review_rate": 5.0,
        "customer_name": "Sharon Bryant",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Lori S.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-19",
        "review_rate": 4.9,
        "customer_name": "Wendy Lee",
        "service_type": "Garage Door Services",
        "tech_name": "Jesse H.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-01",
        "review_rate": 4.9,
        "customer_name": "Shannon Gonzales",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Christine V.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-17",
        "review_rate": 4.8,
        "customer_name": "Brooke White",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "William T.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-01",
        "review_rate": 5.0,
        "customer_name": "Gabriela Mitchell",
        "service_type": "Garage Door Repair",
        "tech_name": "Christine F.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-10",
        "review_rate": 4.9,
        "customer_name": "Beth Perry DDS",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Lorraine F.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.9,
        "customer_name": "Reginald Miller",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jacqueline J.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-19",
        "review_rate": 4.8,
        "customer_name": "Phyllis Martin",
        "service_type": "Garage Door Installation",
        "tech_name": "Frank B.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-25",
        "review_rate": 5.0,
        "customer_name": "Ryan Hill",
        "service_type": "Garage Door Off Track",
        "tech_name": "Chris T.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-17",
        "review_rate": 4.8,
        "customer_name": "Tina Hughes",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Shane F.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-03",
        "review_rate": 4.8,
        "customer_name": "Morgan Hopkins",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jennifer G.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-19",
        "review_rate": 4.9,
        "customer_name": "Dr. Linda Smith",
        "service_type": "Garage Door Opener",
        "tech_name": "Timothy M.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-25",
        "review_rate": 4.8,
        "customer_name": "Dana Johnson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Kenneth R.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-23",
        "review_rate": 5.0,
        "customer_name": "Tiffany Johnson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Ashley B.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-09",
        "review_rate": 5.0,
        "customer_name": "Kelly Hunter",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "David A.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-15",
        "review_rate": 5.0,
        "customer_name": "James Oliver",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Ellen B.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.9,
        "customer_name": "Martin Liu",
        "service_type": "Garage Door Repair",
        "tech_name": "Gary W.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-30",
        "review_rate": 4.9,
        "customer_name": "Haley Fernandez",
        "service_type": "Garage Door Repair",
        "tech_name": "Anna L.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-14",
        "review_rate": 5.0,
        "customer_name": "Jennifer Ferguson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Morgan L.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-30",
        "review_rate": 4.9,
        "customer_name": "William Tyler",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Angela S.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-17",
        "review_rate": 4.8,
        "customer_name": "Michael Snow",
        "service_type": "Garage Door Off Track",
        "tech_name": "John N.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-01",
        "review_rate": 4.9,
        "customer_name": "Jennifer Robinson",
        "service_type": "Garage Door Installation",
        "tech_name": "Jill B.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-08",
        "review_rate": 4.8,
        "customer_name": "Cheryl Ross",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Joseph O.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.9,
        "customer_name": "Nancy Booth",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Nancy H.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-15",
        "review_rate": 4.9,
        "customer_name": "Susan Barker",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Christopher H.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-27",
        "review_rate": 5.0,
        "customer_name": "Tyler Robinson",
        "service_type": "Garage Door Repair",
        "tech_name": "Samantha H.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-13",
        "review_rate": 4.9,
        "customer_name": "Ana Klein",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Amy J.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-22",
        "review_rate": 4.8,
        "customer_name": "Harold Smith",
        "service_type": "Garage Door Installation",
        "tech_name": "Mary F.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-24",
        "review_rate": 4.8,
        "customer_name": "Tricia Stewart",
        "service_type": "Garage Door Services",
        "tech_name": "Donald B.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-03",
        "review_rate": 4.8,
        "customer_name": "Anthony Murray",
        "service_type": "Garage Door Off Track",
        "tech_name": "Corey W.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-20",
        "review_rate": 4.9,
        "customer_name": "George Conley",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jason J.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-11-04",
        "review_rate": 5.0,
        "customer_name": "Jasmine Robinson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Daniel W.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-21",
        "review_rate": 4.9,
        "customer_name": "James Smith",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jeffrey M.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-26",
        "review_rate": 4.8,
        "customer_name": "Scott Baldwin",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Adrienne B.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-09-27",
        "review_rate": 5.0,
        "customer_name": "Sarah Brady",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Juan J.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-21",
        "review_rate": 4.9,
        "customer_name": "Carolyn Russell",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Sara R.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-25",
        "review_rate": 4.9,
        "customer_name": "Willie Kelley",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Theresa J.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-31",
        "review_rate": 4.8,
        "customer_name": "Maria Harrison",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Shawn R.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-17",
        "review_rate": 4.9,
        "customer_name": "Daniel Rose",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Deborah F.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-04",
        "review_rate": 4.9,
        "customer_name": "Terrance Wilson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Audrey C.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-29",
        "review_rate": 4.8,
        "customer_name": "Jason Johnson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Bryan S.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-18",
        "review_rate": 4.8,
        "customer_name": "Jason Adams",
        "service_type": "Garage Door Insulation",
        "tech_name": "Kimberly L.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-04",
        "review_rate": 4.9,
        "customer_name": "Kathy Glass",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Julie K.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-09",
        "review_rate": 4.9,
        "customer_name": "Jillian Lynn MD",
        "service_type": "Garage Door Track Repair",
        "tech_name": "John T.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-02",
        "review_rate": 4.9,
        "customer_name": "Rebecca Wheeler",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Amanda L.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-23",
        "review_rate": 4.9,
        "customer_name": "Gregory Fox",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "April C.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-25",
        "review_rate": 4.8,
        "customer_name": "Victor Hernandez",
        "service_type": "Garage Door Repair",
        "tech_name": "Edward N.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-29",
        "review_rate": 4.9,
        "customer_name": "Sarah Morales",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Justin G.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-15",
        "review_rate": 4.9,
        "customer_name": "Anthony Hensley",
        "service_type": "Garage Door Off Track",
        "tech_name": "Erin W.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-01",
        "review_rate": 5.0,
        "customer_name": "Marc Mullins",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Candace F.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.8,
        "customer_name": "Vincent Patel",
        "service_type": "Garage Door Opener",
        "tech_name": "Heather G.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.9,
        "customer_name": "Christina Adams",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Michael M.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-14",
        "review_rate": 5.0,
        "customer_name": "Melissa Williams",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Melissa M.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-11",
        "review_rate": 4.9,
        "customer_name": "Mary Carrillo",
        "service_type": "Garage Door Off Track",
        "tech_name": "Levi P.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-26",
        "review_rate": 5.0,
        "customer_name": "Jeffery Holder",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jonathan T.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-12",
        "review_rate": 4.8,
        "customer_name": "Bryan Moore",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Kimberly H.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-30",
        "review_rate": 4.9,
        "customer_name": "Robin Miller",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Erin O.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-24",
        "review_rate": 5.0,
        "customer_name": "Ronald Barton",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Victoria S.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-17",
        "review_rate": 4.9,
        "customer_name": "Jonathan Brooks",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Mary L.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-18",
        "review_rate": 4.8,
        "customer_name": "John Brown",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Patrick H.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.9,
        "customer_name": "Kayla Patel",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Brooke W.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-21",
        "review_rate": 5.0,
        "customer_name": "Brian Wilcox",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Sherry G.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-03",
        "review_rate": 5.0,
        "customer_name": "Andrew Schmidt",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Rhonda M.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-20",
        "review_rate": 5.0,
        "customer_name": "Michael Wiggins",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Matthew J.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-04",
        "review_rate": 4.8,
        "customer_name": "Ariel West",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Roger M.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-22",
        "review_rate": 4.9,
        "customer_name": "Anthony Johnson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Ana J.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-30",
        "review_rate": 4.9,
        "customer_name": "Kimberly Brooks",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Amy P.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-06",
        "review_rate": 5.0,
        "customer_name": "Jonathon Shields",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Jacob W.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-06",
        "review_rate": 4.9,
        "customer_name": "Alexander Clark",
        "service_type": "Garage Door Installation",
        "tech_name": "Jennifer B.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-10",
        "review_rate": 5.0,
        "customer_name": "Robert Cook",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jennifer G.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-16",
        "review_rate": 4.8,
        "customer_name": "Albert Wood",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Richard C.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-29",
        "review_rate": 5.0,
        "customer_name": "Olivia Harvey",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Kathryn M.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-22",
        "review_rate": 4.9,
        "customer_name": "Ashley Melton",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Christine G.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-21",
        "review_rate": 4.8,
        "customer_name": "Grant Nguyen",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Anthony S.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-21",
        "review_rate": 4.8,
        "customer_name": "Cynthia Rose",
        "service_type": "Garage Door Services",
        "tech_name": "Natasha G.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-31",
        "review_rate": 4.9,
        "customer_name": "Sharon Chandler",
        "service_type": "Garage Door Repair",
        "tech_name": "Mary B.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-10",
        "review_rate": 4.9,
        "customer_name": "Nicholas Lewis",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Richard W.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-10",
        "review_rate": 4.9,
        "customer_name": "Emily Byrd",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Alexandria H.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-10",
        "review_rate": 4.9,
        "customer_name": "Brandon Lester",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Ashley T.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-26",
        "review_rate": 4.8,
        "customer_name": "Alex Greene",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Michael N.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-23",
        "review_rate": 4.8,
        "customer_name": "Robin Taylor",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Michelle B.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-26",
        "review_rate": 4.9,
        "customer_name": "Matthew Hernandez",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Margaret G.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-04",
        "review_rate": 5.0,
        "customer_name": "Elizabeth Gilbert",
        "service_type": "Garage Door Insulation",
        "tech_name": "Andrew A.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-09",
        "review_rate": 5.0,
        "customer_name": "Angela James",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Evan D.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-27",
        "review_rate": 4.8,
        "customer_name": "Ashley Woodard",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Mary S.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-03",
        "review_rate": 4.9,
        "customer_name": "Mrs. Donna Morgan",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jennifer A.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-31",
        "review_rate": 4.9,
        "customer_name": "Christine Byrd",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Steven R.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-29",
        "review_rate": 5.0,
        "customer_name": "Anna Fox",
        "service_type": "Garage Door Insulation",
        "tech_name": "Bryan R.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-03",
        "review_rate": 4.8,
        "customer_name": "Sara Jones",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Kara M.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-15",
        "review_rate": 4.9,
        "customer_name": "Danielle Thomas",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Robin T.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-20",
        "review_rate": 4.9,
        "customer_name": "Heidi Torres",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jennifer S.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-21",
        "review_rate": 4.9,
        "customer_name": "Anna Miller",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "David P.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.8,
        "customer_name": "Michael Clark",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Angela R.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-27",
        "review_rate": 4.9,
        "customer_name": "Heather Chandler DDS",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Bryan H.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-31",
        "review_rate": 4.8,
        "customer_name": "George Hobbs",
        "service_type": "Garage Door Off Track",
        "tech_name": "Richard L.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-04",
        "review_rate": 4.9,
        "customer_name": "Jerry Johnston",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Brittany W.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-18",
        "review_rate": 4.9,
        "customer_name": "Jacob Potter",
        "service_type": "Garage Door Opener",
        "tech_name": "Michael O.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-09-27",
        "review_rate": 4.9,
        "customer_name": "Jose Mitchell",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Joshua H.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-03",
        "review_rate": 4.9,
        "customer_name": "Brad Miller",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Colleen W.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.8,
        "customer_name": "David Jefferson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Sarah H.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-19",
        "review_rate": 4.9,
        "customer_name": "James Martinez",
        "service_type": "Garage Door Repair",
        "tech_name": "Olivia M.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-27",
        "review_rate": 5.0,
        "customer_name": "Robert Ellis",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Micheal J.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-09-10",
        "review_rate": 4.8,
        "customer_name": "Kyle Adkins",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Gary S.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-12",
        "review_rate": 4.8,
        "customer_name": "Mark Porter",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "James M.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-27",
        "review_rate": 4.9,
        "customer_name": "Jessica Smith",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Ashley S.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.9,
        "customer_name": "Steven Yates",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Nicole R.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-02",
        "review_rate": 4.9,
        "customer_name": "Andrea Suarez",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "David L.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.8,
        "customer_name": "Jason Bailey",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Michael M.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-14",
        "review_rate": 4.8,
        "customer_name": "Kayla Young",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Sarah D.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-12-02",
        "review_rate": 5.0,
        "customer_name": "Joseph Cooper",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Raven S.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.9,
        "customer_name": "Samantha Russell",
        "service_type": "Garage Door Services",
        "tech_name": "Isaiah W.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-12-31",
        "review_rate": 4.9,
        "customer_name": "Lindsey Lopez",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Courtney G.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-13",
        "review_rate": 4.8,
        "customer_name": "Kevin Cannon",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Sheri S.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-01",
        "review_rate": 5.0,
        "customer_name": "Ronald Caldwell",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Pamela M.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-20",
        "review_rate": 4.9,
        "customer_name": "Tonya Soto",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jessica R.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-12-27",
        "review_rate": 4.9,
        "customer_name": "John Perry",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jake B.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-29",
        "review_rate": 4.8,
        "customer_name": "William Nelson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Renee L.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-20",
        "review_rate": 4.9,
        "customer_name": "Danielle Hall",
        "service_type": "Garage Door Installation",
        "tech_name": "Randall T.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-04",
        "review_rate": 4.8,
        "customer_name": "Miguel Mullen",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Darlene W.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-12-28",
        "review_rate": 4.9,
        "customer_name": "Jacob Reed",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Melinda W.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-21",
        "review_rate": 4.9,
        "customer_name": "Ethan Delgado",
        "service_type": "Garage Door Off Track",
        "tech_name": "Amanda S.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-27",
        "review_rate": 4.9,
        "customer_name": "Bethany Davis",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Melinda A.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-09-25",
        "review_rate": 5.0,
        "customer_name": "Christopher Floyd",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Evan W.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-13",
        "review_rate": 4.9,
        "customer_name": "Carlos Farrell",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Katrina S.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-12-29",
        "review_rate": 5.0,
        "customer_name": "Donald Hernandez",
        "service_type": "Garage Door Installation",
        "tech_name": "Tyler G.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-23",
        "review_rate": 4.9,
        "customer_name": "Christina Blake",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Edwin G.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.8,
        "customer_name": "Amanda Valdez",
        "service_type": "Garage Door Opener",
        "tech_name": "Johnny W.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-12-27",
        "review_rate": 4.8,
        "customer_name": "Sydney Barrett",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "John W.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-16",
        "review_rate": 5.0,
        "customer_name": "Andrew Mcdaniel",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Shannon A.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-27",
        "review_rate": 4.9,
        "customer_name": "Jason Gomez DDS",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "William B.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-09-03",
        "review_rate": 4.8,
        "customer_name": "Luke Castillo",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Kathryn T.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-02",
        "review_rate": 5.0,
        "customer_name": "Brandon Cortez",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Carolyn H.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-12-21",
        "review_rate": 4.9,
        "customer_name": "Bradley Foster",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Cheyenne W.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-17",
        "review_rate": 4.8,
        "customer_name": "April Mcdowell",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Elizabeth G.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-12-23",
        "review_rate": 4.9,
        "customer_name": "Tonya Thomas",
        "service_type": "Garage Door Services",
        "tech_name": "Denise G.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-07",
        "review_rate": 4.9,
        "customer_name": "Joshua Davenport",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Monica G.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-07",
        "review_rate": 5.0,
        "customer_name": "Brandon Miller",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Brandy F.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.9,
        "customer_name": "Joshua Russell",
        "service_type": "Garage Door Opener",
        "tech_name": "Randy A.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.8,
        "customer_name": "Nathan Kim",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Samantha L.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-15",
        "review_rate": 5.0,
        "customer_name": "Jessica White",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Alexis H.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-19",
        "review_rate": 5.0,
        "customer_name": "Christine Mckenzie",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Stephanie K.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-21",
        "review_rate": 4.9,
        "customer_name": "Michael Burch",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Tina L.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-25",
        "review_rate": 5.0,
        "customer_name": "Dennis Pennington",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Desiree T.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-23",
        "review_rate": 4.9,
        "customer_name": "Derek Hill",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Wendy H.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-15",
        "review_rate": 4.9,
        "customer_name": "Matthew Haley",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Kimberly G.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-09-27",
        "review_rate": 5.0,
        "customer_name": "Timothy Payne",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Ryan B.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-30",
        "review_rate": 4.9,
        "customer_name": "Katrina Boyd",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Connie S.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-15",
        "review_rate": 4.8,
        "customer_name": "Raymond Vazquez",
        "service_type": "Garage Door Off Track",
        "tech_name": "Dorothy S.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-18",
        "review_rate": 5.0,
        "customer_name": "Kelly Jones",
        "service_type": "Garage Door Installation",
        "tech_name": "Mary M.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-18",
        "review_rate": 4.9,
        "customer_name": "Miguel Cordova",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Lisa C.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-07",
        "review_rate": 5.0,
        "customer_name": "Lisa Martin",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Kimberly H.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-13",
        "review_rate": 4.9,
        "customer_name": "Justin Moyer",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Derrick R.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-06",
        "review_rate": 5.0,
        "customer_name": "Michelle Fields",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Brenda J.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-15",
        "review_rate": 4.9,
        "customer_name": "Darrell Humphrey",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Penny G.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-13",
        "review_rate": 4.9,
        "customer_name": "Thomas Morgan",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Megan C.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-18",
        "review_rate": 4.8,
        "customer_name": "Richard Evans",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Ellen G.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-14",
        "review_rate": 4.9,
        "customer_name": "Sharon Young",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Marisa R.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-08",
        "review_rate": 5.0,
        "customer_name": "Vanessa Scott",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Hannah H.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-15",
        "review_rate": 4.8,
        "customer_name": "April Booker",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Gabriel D.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-06",
        "review_rate": 5.0,
        "customer_name": "Douglas Smith",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Steven C.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-05",
        "review_rate": 5.0,
        "customer_name": "Sarah Dyer",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Kelly C.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-19",
        "review_rate": 4.9,
        "customer_name": "Briana Huff",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Justin M.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.9,
        "customer_name": "Steven Dunn",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Sue W.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-14",
        "review_rate": 5.0,
        "customer_name": "Stephen Hicks",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Monica C.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-21",
        "review_rate": 4.8,
        "customer_name": "Willie Perez",
        "service_type": "Garage Door Installation",
        "tech_name": "Theresa C.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-09-21",
        "review_rate": 4.9,
        "customer_name": "Douglas Rodriguez",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Ian B.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-04",
        "review_rate": 4.9,
        "customer_name": "Carlos Booth",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Paul L.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-16",
        "review_rate": 4.9,
        "customer_name": "Michelle Wade",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Jasmine H.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-22",
        "review_rate": 4.9,
        "customer_name": "Justin Davis",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Kirk B.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-09-14",
        "review_rate": 4.9,
        "customer_name": "Brittany Alvarado",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Dominique S.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-24",
        "review_rate": 4.9,
        "customer_name": "Joshua Gill",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Stacy T.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-03",
        "review_rate": 5.0,
        "customer_name": "Michelle Costa",
        "service_type": "Garage Door Insulation",
        "tech_name": "William T.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-22",
        "review_rate": 5.0,
        "customer_name": "Sean Zuniga",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jose F.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.9,
        "customer_name": "Laura Shelton",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Cheryl P.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-02",
        "review_rate": 4.8,
        "customer_name": "Erika Martin",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jeffrey G.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-07",
        "review_rate": 4.8,
        "customer_name": "Samuel Foley",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Austin T.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.8,
        "customer_name": "Shannon Herring",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Christine G.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-09-15",
        "review_rate": 5.0,
        "customer_name": "Brittany Silva",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Christina R.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.9,
        "customer_name": "John Mejia",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Leonard H.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-28",
        "review_rate": 5.0,
        "customer_name": "Brianna White",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "John B.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-10",
        "review_rate": 4.8,
        "customer_name": "Jessica Perez",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Edward N.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-18",
        "review_rate": 4.9,
        "customer_name": "Dr. Edgar Reynolds",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Katie J.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-12",
        "review_rate": 4.8,
        "customer_name": "Marc Smith",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jessica F.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-18",
        "review_rate": 4.8,
        "customer_name": "Mary Hughes",
        "service_type": "Garage Door Installation",
        "tech_name": "Leslie M.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.9,
        "customer_name": "Stephen Hogan",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Michael O.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-09",
        "review_rate": 4.8,
        "customer_name": "Ashley Smith",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Daniel A.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-23",
        "review_rate": 4.8,
        "customer_name": "David Marshall",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "David P.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-15",
        "review_rate": 5.0,
        "customer_name": "Douglas Jackson",
        "service_type": "Garage Door Repair",
        "tech_name": "Wesley M.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-09",
        "review_rate": 5.0,
        "customer_name": "Jonathan Davis",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Ashley H.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-18",
        "review_rate": 5.0,
        "customer_name": "Larry Butler",
        "service_type": "Garage Door Services",
        "tech_name": "Steven B.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-19",
        "review_rate": 4.8,
        "customer_name": "Rhonda Delgado",
        "service_type": "Garage Door Installation",
        "tech_name": "Alexander S.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-03",
        "review_rate": 4.9,
        "customer_name": "Eric Ho",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Patrick M.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-01",
        "review_rate": 4.8,
        "customer_name": "Patrick Lee",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jessica J.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.9,
        "customer_name": "David Bradshaw",
        "service_type": "Garage Door Off Track",
        "tech_name": "Chase H.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-26",
        "review_rate": 4.8,
        "customer_name": "Amy Carpenter",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Adrian C.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-10",
        "review_rate": 5.0,
        "customer_name": "Michelle Walter",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "David H.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.9,
        "customer_name": "Stacy Hampton",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Amber F.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-24",
        "review_rate": 4.9,
        "customer_name": "Amanda Myers",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Joseph B.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-08",
        "review_rate": 5.0,
        "customer_name": "Margaret Chavez",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Alex G.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-02",
        "review_rate": 5.0,
        "customer_name": "Mark Booth",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Charles S.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-18",
        "review_rate": 5.0,
        "customer_name": "Chad Palmer",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Leslie H.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-29",
        "review_rate": 4.8,
        "customer_name": "Susan Hernandez",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Anthony L.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-21",
        "review_rate": 4.9,
        "customer_name": "John Johns",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Andrea J.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-24",
        "review_rate": 4.9,
        "customer_name": "Shannon Hughes",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Emily P.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-14",
        "review_rate": 4.9,
        "customer_name": "Jennifer Bennett",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Larry F.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-18",
        "review_rate": 4.9,
        "customer_name": "Gregg Lawson",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Kevin W.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-07",
        "review_rate": 4.9,
        "customer_name": "Ryan Lane",
        "service_type": "Garage Door Installation",
        "tech_name": "Bryan C.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-08",
        "review_rate": 5.0,
        "customer_name": "Angie Meyers",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Kirk K.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-08",
        "review_rate": 4.9,
        "customer_name": "Alexis Wilson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Samuel S.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-21",
        "review_rate": 4.9,
        "customer_name": "Donna Ayers",
        "service_type": "Garage Door Installation",
        "tech_name": "Seth L.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.9,
        "customer_name": "Danielle Sutton",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Edward J.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-22",
        "review_rate": 4.8,
        "customer_name": "Ellen Molina",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Robert P.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-14",
        "review_rate": 4.8,
        "customer_name": "Rebecca Brown",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Eric L.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.9,
        "customer_name": "Paul Preston",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jennifer J.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-01",
        "review_rate": 4.9,
        "customer_name": "Mark Allen",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Craig G.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-12",
        "review_rate": 5.0,
        "customer_name": "John Odonnell",
        "service_type": "Garage Door Opener",
        "tech_name": "Victoria B.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-28",
        "review_rate": 4.8,
        "customer_name": "Gene Bennett",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Laura M.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.8,
        "customer_name": "Todd Herrera",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Karen F.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-02",
        "review_rate": 4.9,
        "customer_name": "Hannah James",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Kimberly G.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-23",
        "review_rate": 4.8,
        "customer_name": "Christopher Smith",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Amy B.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-24",
        "review_rate": 4.9,
        "customer_name": "Kevin Watson",
        "service_type": "Garage Door Repair",
        "tech_name": "John V.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-08",
        "review_rate": 4.8,
        "customer_name": "Cory Warren",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Sharon C.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.9,
        "customer_name": "James Williams",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Evelyn J.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-19",
        "review_rate": 4.8,
        "customer_name": "William Stewart",
        "service_type": "Garage Door Insulation",
        "tech_name": "Luke W.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-23",
        "review_rate": 4.9,
        "customer_name": "Misty Rodriguez",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Charles E.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-29",
        "review_rate": 5.0,
        "customer_name": "Rose Anderson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Alice B.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-26",
        "review_rate": 4.9,
        "customer_name": "Mr. Anthony Hayes",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Rachel W.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-19",
        "review_rate": 4.9,
        "customer_name": "Leah Rodriguez",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Nicholas M.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-19",
        "review_rate": 5.0,
        "customer_name": "Mary Hill",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Natalie B.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-13",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Lucas",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Mackenzie C.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-04",
        "review_rate": 4.9,
        "customer_name": "Patrick Porter",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "James R.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-10",
        "review_rate": 4.9,
        "customer_name": "Alison Young",
        "service_type": "Garage Door Opener",
        "tech_name": "David C.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-03",
        "review_rate": 5.0,
        "customer_name": "Emily Williams",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Andrea T.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-08",
        "review_rate": 4.8,
        "customer_name": "Elizabeth Estes",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Christopher F.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-22",
        "review_rate": 4.9,
        "customer_name": "Beth Mata",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jesse G.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-15",
        "review_rate": 4.9,
        "customer_name": "Ashley Miranda",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Patrick E.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-25",
        "review_rate": 5.0,
        "customer_name": "Wendy Vang",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Diane W.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-21",
        "review_rate": 4.8,
        "customer_name": "Morgan Barr",
        "service_type": "Garage Door Opener",
        "tech_name": "Eric P.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-05",
        "review_rate": 5.0,
        "customer_name": "Desiree Rose",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Michelle C.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-07",
        "review_rate": 5.0,
        "customer_name": "Ashley Caldwell",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Brian P.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-29",
        "review_rate": 4.9,
        "customer_name": "Karla Wolfe",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Elizabeth B.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.9,
        "customer_name": "Emily Phillips",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Robert S.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-14",
        "review_rate": 4.9,
        "customer_name": "Lauren Mcfarland",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Gregory W.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-23",
        "review_rate": 4.9,
        "customer_name": "Shawn Mullins",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Shannon C.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-28",
        "review_rate": 4.8,
        "customer_name": "Angela Campbell",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Ariel P.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-30",
        "review_rate": 5.0,
        "customer_name": "Alejandro Moore",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Steve D.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-21",
        "review_rate": 4.8,
        "customer_name": "Bridget Hays",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Mark B.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-22",
        "review_rate": 5.0,
        "customer_name": "Michelle Brady",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "John W.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-04",
        "review_rate": 5.0,
        "customer_name": "Daniel Estrada DDS",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Kristy H.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-07-26",
        "review_rate": 5.0,
        "customer_name": "Joe Harper",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Ashley M.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-13",
        "review_rate": 5.0,
        "customer_name": "Mark Ellis DDS",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jean H.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-19",
        "review_rate": 4.8,
        "customer_name": "John Hernandez",
        "service_type": "Garage Door Services",
        "tech_name": "Sarah L.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-23",
        "review_rate": 4.9,
        "customer_name": "Paul Horn",
        "service_type": "Garage Door Services",
        "tech_name": "Alex S.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-31",
        "review_rate": 5.0,
        "customer_name": "Jesse Carpenter",
        "service_type": "Garage Door Installation",
        "tech_name": "Jacob L.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.8,
        "customer_name": "Michael Carroll",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Cory R.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-17",
        "review_rate": 5.0,
        "customer_name": "Travis Summers MD",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Eric W.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-07",
        "review_rate": 4.8,
        "customer_name": "Lisa Brown",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Gregory M.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-26",
        "review_rate": 4.8,
        "customer_name": "Joshua Rivera",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Eric L.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-29",
        "review_rate": 4.8,
        "customer_name": "Kristin Medina",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Joseph S.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-24",
        "review_rate": 4.9,
        "customer_name": "Dr. Allison Lane",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jerome M.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-14",
        "review_rate": 4.9,
        "customer_name": "Karen Lester",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Raymond N.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-07-28",
        "review_rate": 5.0,
        "customer_name": "Edward Chase",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Alyssa M.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-07-12",
        "review_rate": 4.9,
        "customer_name": "Tiffany Hopkins",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Tamara R.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-03",
        "review_rate": 5.0,
        "customer_name": "Kristy Gordon",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jeffrey P.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.9,
        "customer_name": "Aaron Waller",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Hannah H.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-21",
        "review_rate": 4.9,
        "customer_name": "Michael Clark",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Brett L.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-08",
        "review_rate": 4.9,
        "customer_name": "Daniel Ali",
        "service_type": "Garage Door Repair",
        "tech_name": "Kevin P.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-23",
        "review_rate": 4.9,
        "customer_name": "Jennifer Graves",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Amanda R.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-12",
        "review_rate": 4.8,
        "customer_name": "Lori Padilla",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Robin J.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-18",
        "review_rate": 4.8,
        "customer_name": "Nicole Graham",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Gregory T.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-07-06",
        "review_rate": 4.9,
        "customer_name": "Meghan Allen",
        "service_type": "Garage Door Opener",
        "tech_name": "Laura U.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-25",
        "review_rate": 4.9,
        "customer_name": "Darlene Wells",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Brianna M.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-04",
        "review_rate": 5.0,
        "customer_name": "Kimberly Lane",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Ann A.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-09",
        "review_rate": 5.0,
        "customer_name": "Renee Randall",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Kristen D.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-08",
        "review_rate": 4.9,
        "customer_name": "Heather Sanchez",
        "service_type": "Garage Door Off Track",
        "tech_name": "Joyce J.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-22",
        "review_rate": 4.9,
        "customer_name": "Thomas Hernandez",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Stacy I.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-27",
        "review_rate": 4.8,
        "customer_name": "Barbara Nicholson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Zachary C.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-07",
        "review_rate": 5.0,
        "customer_name": "David Acosta",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Robert D.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-02",
        "review_rate": 5.0,
        "customer_name": "Erin King",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Barbara S.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-05",
        "review_rate": 4.9,
        "customer_name": "James Park",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Stacy W.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-25",
        "review_rate": 4.9,
        "customer_name": "Blake Stewart",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Erik S.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-05",
        "review_rate": 4.9,
        "customer_name": "Tina Stewart",
        "service_type": "Garage Door Installation",
        "tech_name": "Duane F.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-18",
        "review_rate": 4.8,
        "customer_name": "Matthew Joyce",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "William P.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-20",
        "review_rate": 4.9,
        "customer_name": "Patricia Joseph",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Terri J.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-18",
        "review_rate": 4.9,
        "customer_name": "Maria Williams",
        "service_type": "Garage Door Installation",
        "tech_name": "Maureen R.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-01",
        "review_rate": 5.0,
        "customer_name": "Charles Johnson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Alicia D.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-03",
        "review_rate": 5.0,
        "customer_name": "James Wright",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Laura T.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.9,
        "customer_name": "James Smith",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Andrea B.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.9,
        "customer_name": "Luke Smith",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Andrew K.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-18",
        "review_rate": 4.9,
        "customer_name": "Eric Williams",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Henry P.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-29",
        "review_rate": 4.9,
        "customer_name": "Emily Warner",
        "service_type": "Garage Door Insulation",
        "tech_name": "Noah S.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-05",
        "review_rate": 5.0,
        "customer_name": "Danielle Garcia",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Devin F.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-07",
        "review_rate": 4.9,
        "customer_name": "Jennifer Andrews",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Charles J.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-12",
        "review_rate": 4.9,
        "customer_name": "Logan Dodson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Aaron J.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.9,
        "customer_name": "Mrs. Laura Roman",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Bradley M.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-14",
        "review_rate": 5.0,
        "customer_name": "William Rivera",
        "service_type": "Garage Door Repair",
        "tech_name": "Duane P.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.8,
        "customer_name": "Angela Taylor",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jennifer L.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-10",
        "review_rate": 4.9,
        "customer_name": "Jacob Allison",
        "service_type": "Residential Garage Door Services",
        "tech_name": "William L.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-25",
        "review_rate": 4.9,
        "customer_name": "Cynthia Thomas",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Angela V.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-30",
        "review_rate": 5.0,
        "customer_name": "Janice Foster",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Joseph J.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-24",
        "review_rate": 4.9,
        "customer_name": "Amy Jackson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Angela W.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-16",
        "review_rate": 4.9,
        "customer_name": "Mary Lyons",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Alexander J.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-12",
        "review_rate": 4.9,
        "customer_name": "John Sullivan",
        "service_type": "Garage Door Services",
        "tech_name": "Catherine C.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-03",
        "review_rate": 4.9,
        "customer_name": "Marvin Mueller",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jesse R.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-03",
        "review_rate": 4.9,
        "customer_name": "Casey Smith",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Crystal M.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-18",
        "review_rate": 4.9,
        "customer_name": "Destiny Wilson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Kayla G.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-23",
        "review_rate": 4.8,
        "customer_name": "David Hernandez",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jamie B.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-02",
        "review_rate": 5.0,
        "customer_name": "Caleb Butler",
        "service_type": "Garage Door Opener",
        "tech_name": "Christian C.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-21",
        "review_rate": 4.9,
        "customer_name": "Jennifer Combs",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Debbie M.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-07",
        "review_rate": 4.8,
        "customer_name": "Melanie Welch",
        "service_type": "Garage Door Repair",
        "tech_name": "Pamela W.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-05",
        "review_rate": 4.8,
        "customer_name": "Jennifer Oliver",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Gina B.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-05",
        "review_rate": 5.0,
        "customer_name": "Susan Nguyen",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jessica R.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-20",
        "review_rate": 4.8,
        "customer_name": "Ricardo Ward",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "William M.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-09",
        "review_rate": 4.9,
        "customer_name": "Mrs. Laura Horton",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Evan P.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-12",
        "review_rate": 4.8,
        "customer_name": "Mercedes Gonzalez",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Kristopher V.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-16",
        "review_rate": 5.0,
        "customer_name": "Pamela Mcknight",
        "service_type": "Garage Door Services",
        "tech_name": "Patricia G.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-23",
        "review_rate": 4.9,
        "customer_name": "Patrick Smith",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Joel G.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.9,
        "customer_name": "Crystal Wilson",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Lindsay C.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-27",
        "review_rate": 5.0,
        "customer_name": "Tammy Gates",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jessica G.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-12",
        "review_rate": 4.8,
        "customer_name": "James Craig",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Maurice T.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.8,
        "customer_name": "Jason Lloyd",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Melissa W.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-03",
        "review_rate": 4.8,
        "customer_name": "Emily Harper",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "John V.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-01",
        "review_rate": 4.9,
        "customer_name": "Ariana Berger DVM",
        "service_type": "Garage Door Track Repair",
        "tech_name": "George W.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.8,
        "customer_name": "Adam Roberts",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Elizabeth C.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-30",
        "review_rate": 4.8,
        "customer_name": "Samuel Martinez",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Crystal D.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-16",
        "review_rate": 4.8,
        "customer_name": "Rebecca Crawford",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Taylor M.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-03",
        "review_rate": 4.9,
        "customer_name": "Nicole Ray",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Robert C.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-13",
        "review_rate": 4.8,
        "customer_name": "Allen Evans",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Robert S.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-01",
        "review_rate": 4.8,
        "customer_name": "Todd Cervantes",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Rachel S.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-03",
        "review_rate": 5.0,
        "customer_name": "Victoria Norman",
        "service_type": "Garage Door Opener",
        "tech_name": "Katherine A.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-19",
        "review_rate": 5.0,
        "customer_name": "Franklin Richardson Jr.",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Kevin S.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-19",
        "review_rate": 4.9,
        "customer_name": "Megan Mccoy",
        "service_type": "Garage Door Installation",
        "tech_name": "Carla P.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-07",
        "review_rate": 4.9,
        "customer_name": "Sandra Bartlett",
        "service_type": "Garage Door Installation",
        "tech_name": "Herbert F.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-02",
        "review_rate": 5.0,
        "customer_name": "Gregory Pennington",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jason S.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-24",
        "review_rate": 4.9,
        "customer_name": "Helen Robinson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Stephanie B.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.9,
        "customer_name": "Kelly Velasquez",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Rebecca B.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-24",
        "review_rate": 4.8,
        "customer_name": "Ann Byrd",
        "service_type": "Garage Door Opener",
        "tech_name": "Kenneth A.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-19",
        "review_rate": 5.0,
        "customer_name": "Whitney Harris",
        "service_type": "Garage Door Installation",
        "tech_name": "Annette S.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.9,
        "customer_name": "Larry Wilson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Pamela W.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-22",
        "review_rate": 4.8,
        "customer_name": "Frances Jackson",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "David V.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-12",
        "review_rate": 4.9,
        "customer_name": "Jennifer Miller",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Heather S.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-09-22",
        "review_rate": 4.9,
        "customer_name": "Jennifer Johnson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Danielle D.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-01",
        "review_rate": 4.9,
        "customer_name": "Joseph Mccarthy",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Melissa D.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-11",
        "review_rate": 5.0,
        "customer_name": "Benjamin Fisher",
        "service_type": "Garage Door Insulation",
        "tech_name": "Tanya M.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-18",
        "review_rate": 5.0,
        "customer_name": "Joshua Frost",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Michael T.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-09-27",
        "review_rate": 4.8,
        "customer_name": "Debra Lynch",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Colleen T.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-24",
        "review_rate": 4.9,
        "customer_name": "John Kent",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Juan K.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-08",
        "review_rate": 4.9,
        "customer_name": "Brandy Adams",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Cynthia G.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-25",
        "review_rate": 4.9,
        "customer_name": "Stephen Leon",
        "service_type": "Garage Door Installation",
        "tech_name": "Cynthia C.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-27",
        "review_rate": 4.9,
        "customer_name": "Jennifer Garcia",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Judith D.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-12",
        "review_rate": 5.0,
        "customer_name": "Maria Davis",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Beverly G.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-16",
        "review_rate": 4.8,
        "customer_name": "Evan Reyes",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Calvin P.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-23",
        "review_rate": 5.0,
        "customer_name": "Taylor Lam",
        "service_type": "Garage Door Opener",
        "tech_name": "Jerry A.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-11",
        "review_rate": 5.0,
        "customer_name": "Kimberly Ryan",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Stephanie S.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-25",
        "review_rate": 5.0,
        "customer_name": "Martin Stokes",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Megan G.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-19",
        "review_rate": 4.9,
        "customer_name": "Melissa Garcia",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Sarah H.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-03",
        "review_rate": 4.9,
        "customer_name": "Nathan Lewis",
        "service_type": "Garage Door Insulation",
        "tech_name": "Timothy B.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-12",
        "review_rate": 4.9,
        "customer_name": "Jennifer Avila",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Tracey K.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-12",
        "review_rate": 5.0,
        "customer_name": "Jack Johnson",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Michael C.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-18",
        "review_rate": 5.0,
        "customer_name": "Darin Smith",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "David T.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-10",
        "review_rate": 4.9,
        "customer_name": "Randy Bennett",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Mark H.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-10",
        "review_rate": 4.8,
        "customer_name": "Jeffrey Russell",
        "service_type": "Garage Door Opener",
        "tech_name": "Danielle C.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-25",
        "review_rate": 5.0,
        "customer_name": "John Curry",
        "service_type": "Garage Door Repair",
        "tech_name": "Donald F.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-15",
        "review_rate": 5.0,
        "customer_name": "Brent Green",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Paul D.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-11",
        "review_rate": 5.0,
        "customer_name": "Ronald Nunez",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Laura B.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-20",
        "review_rate": 4.9,
        "customer_name": "Denise Shaffer",
        "service_type": "Garage Door Repair",
        "tech_name": "Barbara W.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-23",
        "review_rate": 4.9,
        "customer_name": "Cynthia Ballard",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Henry G.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-09",
        "review_rate": 4.9,
        "customer_name": "Lisa Gutierrez",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Joseph L.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-13",
        "review_rate": 4.9,
        "customer_name": "James Calderon",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Edward P.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-29",
        "review_rate": 4.9,
        "customer_name": "Jennifer Moss",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Peggy H.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-18",
        "review_rate": 4.8,
        "customer_name": "Jeremy Marks",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Vanessa F.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-11",
        "review_rate": 4.8,
        "customer_name": "Amanda Romero",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Chris G.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-05",
        "review_rate": 5.0,
        "customer_name": "Mary Flores",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Anthony R.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.9,
        "customer_name": "Tara Mckinney",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Edward G.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-11",
        "review_rate": 4.9,
        "customer_name": "Ashley Dickerson",
        "service_type": "Garage Door Services",
        "tech_name": "Deborah M.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-05",
        "review_rate": 4.9,
        "customer_name": "Ryan Harris",
        "service_type": "Garage Door Off Track",
        "tech_name": "Vanessa H.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.8,
        "customer_name": "Richard Jones",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Denise C.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-17",
        "review_rate": 4.8,
        "customer_name": "Guy Hunter",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jacob C.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-03",
        "review_rate": 4.9,
        "customer_name": "Jeremy Martin",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Donna Z.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.8,
        "customer_name": "William Snyder",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Anna W.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-17",
        "review_rate": 4.9,
        "customer_name": "Steven Avila",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Maurice S.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-23",
        "review_rate": 4.8,
        "customer_name": "David Coleman",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Mario O.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-29",
        "review_rate": 4.9,
        "customer_name": "Kevin Cooper",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jonathan W.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-03",
        "review_rate": 4.9,
        "customer_name": "Danielle Collins",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Erica S.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-01",
        "review_rate": 5.0,
        "customer_name": "James Cook",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Cynthia H.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-28",
        "review_rate": 4.9,
        "customer_name": "Timothy Smith",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Tracey M.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-16",
        "review_rate": 4.9,
        "customer_name": "Jasmine Barnes",
        "service_type": "Garage Door Repair",
        "tech_name": "David G.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-09",
        "review_rate": 5.0,
        "customer_name": "Tammy Lawrence",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Robin I.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-15",
        "review_rate": 4.9,
        "customer_name": "Mr. Richard Gibson",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Patricia C.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-23",
        "review_rate": 4.9,
        "customer_name": "Mary Simpson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Cheryl J.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.8,
        "customer_name": "Elizabeth Williams",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Kevin S.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-08",
        "review_rate": 5.0,
        "customer_name": "Joshua Moore",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Heather M.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-15",
        "review_rate": 4.9,
        "customer_name": "James Smith",
        "service_type": "Garage Door Repair",
        "tech_name": "Amanda B.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-20",
        "review_rate": 4.8,
        "customer_name": "Rebecca Rubio",
        "service_type": "Garage Door Off Track",
        "tech_name": "Robert A.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-11",
        "review_rate": 5.0,
        "customer_name": "William Wilson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Madison G.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-08",
        "review_rate": 4.9,
        "customer_name": "Carol Patterson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Antonio M.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-16",
        "review_rate": 4.9,
        "customer_name": "Troy Decker",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Seth T.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.8,
        "customer_name": "Susan Patterson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Laura S.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.9,
        "customer_name": "Megan Williams",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Frederick R.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-10",
        "review_rate": 5.0,
        "customer_name": "Mrs. Abigail Gonzales DDS",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Christopher B.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-08",
        "review_rate": 4.8,
        "customer_name": "Kayla Levy",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Melissa S.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-30",
        "review_rate": 4.8,
        "customer_name": "Cassie Carpenter",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Briana M.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-26",
        "review_rate": 5.0,
        "customer_name": "Rebecca Herrera",
        "service_type": "Garage Door Installation",
        "tech_name": "Ashley H.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-09",
        "review_rate": 5.0,
        "customer_name": "Michael Boyd",
        "service_type": "Garage Door Off Track",
        "tech_name": "Erik F.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-03",
        "review_rate": 5.0,
        "customer_name": "Phillip Shaw",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Karen B.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-25",
        "review_rate": 4.8,
        "customer_name": "Dillon Green",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Gregory P.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-02",
        "review_rate": 4.8,
        "customer_name": "Jessica Walker",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Olivia J.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-09-08",
        "review_rate": 4.9,
        "customer_name": "James Pugh",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Kenneth N.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.8,
        "customer_name": "Steven Anderson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Kenneth N.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-18",
        "review_rate": 4.9,
        "customer_name": "Joseph Barber",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "John A.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-09",
        "review_rate": 5.0,
        "customer_name": "Brian Powell",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Monique W.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-06",
        "review_rate": 5.0,
        "customer_name": "Melissa Small",
        "service_type": "Garage Door Services",
        "tech_name": "Anthony H.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-09-22",
        "review_rate": 4.8,
        "customer_name": "Cynthia Hahn",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Christopher Y.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-15",
        "review_rate": 4.8,
        "customer_name": "Christopher Velasquez",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Catherine G.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.8,
        "customer_name": "Deborah Patterson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Dwayne T.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.8,
        "customer_name": "Kristen Long",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Scott M.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-12",
        "review_rate": 4.9,
        "customer_name": "Daniel Davis",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Brittany C.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-25",
        "review_rate": 5.0,
        "customer_name": "Natalie Williams",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Alexandra L.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-16",
        "review_rate": 4.9,
        "customer_name": "Jonathan Patel",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Laura P.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.9,
        "customer_name": "Johnny Hanson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jeremy S.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-20",
        "review_rate": 4.8,
        "customer_name": "Joseph Lee",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Tamara M.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.8,
        "customer_name": "Cynthia Lopez",
        "service_type": "Garage Door Off Track",
        "tech_name": "Christopher P.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-12",
        "review_rate": 4.8,
        "customer_name": "Dr. Stephanie Taylor",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Carolyn B.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-02",
        "review_rate": 4.9,
        "customer_name": "Jasmine Ross",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Rodney G.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-30",
        "review_rate": 4.9,
        "customer_name": "Meredith Jones",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Shaun B.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-05",
        "review_rate": 5.0,
        "customer_name": "Scott Taylor",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "James L.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-16",
        "review_rate": 5.0,
        "customer_name": "Tammy White",
        "service_type": "Garage Door Insulation",
        "tech_name": "Kimberly P.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-30",
        "review_rate": 4.8,
        "customer_name": "Christopher Evans",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Paul D.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-12",
        "review_rate": 5.0,
        "customer_name": "John Poole",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Sheila H.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-08",
        "review_rate": 4.9,
        "customer_name": "Stephen Freeman MD",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Stephen C.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-16",
        "review_rate": 4.9,
        "customer_name": "Margaret Lambert",
        "service_type": "Garage Door Insulation",
        "tech_name": "Allen P.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-20",
        "review_rate": 4.9,
        "customer_name": "Sarah Francis",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "John M.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.9,
        "customer_name": "John Acosta",
        "service_type": "Garage Door Installation",
        "tech_name": "Robert M.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-12",
        "review_rate": 4.8,
        "customer_name": "Eric Peterson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Kevin T.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-05",
        "review_rate": 4.9,
        "customer_name": "Hannah Yates",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Beth B.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.8,
        "customer_name": "Hannah Davis",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Melissa H.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.9,
        "customer_name": "Mary Young",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Tanya W.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-22",
        "review_rate": 4.8,
        "customer_name": "Michael Porter",
        "service_type": "Garage Door Installation",
        "tech_name": "Jon B.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-20",
        "review_rate": 4.9,
        "customer_name": "Mark Weaver",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Caleb W.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-12",
        "review_rate": 4.9,
        "customer_name": "Spencer Wagner",
        "service_type": "Garage Door Insulation",
        "tech_name": "Gerald B.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.9,
        "customer_name": "Dwayne Anderson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Larry A.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-22",
        "review_rate": 5.0,
        "customer_name": "Tracy Booth",
        "service_type": "Garage Door Installation",
        "tech_name": "Cathy M.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-19",
        "review_rate": 5.0,
        "customer_name": "Richard Lewis",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Michael P.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-04",
        "review_rate": 5.0,
        "customer_name": "Beth Ramirez",
        "service_type": "Garage Door Track Repair",
        "tech_name": "James G.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-14",
        "review_rate": 4.9,
        "customer_name": "Richard Ruiz",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Anna L.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-09-10",
        "review_rate": 4.9,
        "customer_name": "Stephanie Olsen",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Diana M.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-16",
        "review_rate": 4.9,
        "customer_name": "Paula Shaffer",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Lisa R.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-10",
        "review_rate": 5.0,
        "customer_name": "Lisa Stone",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Darryl B.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-30",
        "review_rate": 4.9,
        "customer_name": "Patricia Li",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Timothy H.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-24",
        "review_rate": 5.0,
        "customer_name": "Frederick Khan",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Charles R.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-09",
        "review_rate": 4.9,
        "customer_name": "Mr. Jacob May",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Eileen S.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-29",
        "review_rate": 4.9,
        "customer_name": "Katherine Simon",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Robert C.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-12",
        "review_rate": 4.8,
        "customer_name": "Tammy Huff",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jason S.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-29",
        "review_rate": 5.0,
        "customer_name": "Amanda Campbell",
        "service_type": "Garage Door Opener",
        "tech_name": "Danielle B.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-05",
        "review_rate": 4.8,
        "customer_name": "Joshua Warren",
        "service_type": "Garage Door Installation",
        "tech_name": "James R.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-07",
        "review_rate": 4.9,
        "customer_name": "Austin Garcia",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Johnny R.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-12",
        "review_rate": 4.8,
        "customer_name": "Karen Barker",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Diane C.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-01",
        "review_rate": 4.9,
        "customer_name": "James Sanchez",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jesse C.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-01",
        "review_rate": 4.9,
        "customer_name": "Dr. Dennis Robinson",
        "service_type": "Garage Door Opener",
        "tech_name": "Vanessa S.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-09",
        "review_rate": 4.9,
        "customer_name": "Kelly Haney",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Steven M.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-29",
        "review_rate": 4.9,
        "customer_name": "Rodney Martinez",
        "service_type": "Garage Door Repair",
        "tech_name": "Keith W.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-30",
        "review_rate": 4.8,
        "customer_name": "Ryan Rogers",
        "service_type": "Garage Door Off Track",
        "tech_name": "Paul D.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-01",
        "review_rate": 4.8,
        "customer_name": "Jessica Lewis",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Robert H.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-25",
        "review_rate": 5.0,
        "customer_name": "Scott Ryan",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Charles A.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-18",
        "review_rate": 4.9,
        "customer_name": "Tony Richardson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Robert A.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-19",
        "review_rate": 4.8,
        "customer_name": "Jose Contreras DDS",
        "service_type": "Garage Door Insulation",
        "tech_name": "Ebony B.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-05",
        "review_rate": 4.8,
        "customer_name": "Kenneth Nelson",
        "service_type": "Garage Door Opener",
        "tech_name": "Christine S.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-01",
        "review_rate": 4.9,
        "customer_name": "Debra Martin",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Carrie R.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-19",
        "review_rate": 4.9,
        "customer_name": "Gregg Cruz",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Darrell P.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-05",
        "review_rate": 4.8,
        "customer_name": "Debra Morgan",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Joshua R.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-22",
        "review_rate": 5.0,
        "customer_name": "Barbara Malone",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Daniel B.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-08",
        "review_rate": 4.9,
        "customer_name": "Greg Howell",
        "service_type": "Garage Door Opener",
        "tech_name": "April C.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-05",
        "review_rate": 5.0,
        "customer_name": "Randall Grimes",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Brandon N.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-10",
        "review_rate": 5.0,
        "customer_name": "Cynthia Miller",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Patricia D.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-15",
        "review_rate": 4.9,
        "customer_name": "Joseph Kidd",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Carlos J.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-28",
        "review_rate": 4.9,
        "customer_name": "Gary Waller",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jennifer M.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-20",
        "review_rate": 5.0,
        "customer_name": "Jessica Brown",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Helen S.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-14",
        "review_rate": 4.9,
        "customer_name": "Janet Herrera",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Keith P.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-23",
        "review_rate": 5.0,
        "customer_name": "Amanda Harris",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Eric T.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-06",
        "review_rate": 4.9,
        "customer_name": "Cody Mcdaniel",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Sandra M.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-30",
        "review_rate": 4.9,
        "customer_name": "Phillip Drake",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Elizabeth B.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-12",
        "review_rate": 5.0,
        "customer_name": "James Hanson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Melissa S.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-19",
        "review_rate": 4.8,
        "customer_name": "Adam Gilbert",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Brianna T.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-12",
        "review_rate": 4.9,
        "customer_name": "Jennifer Gates",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Kenneth W.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-29",
        "review_rate": 4.9,
        "customer_name": "Michele Lozano",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Rebecca T.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-25",
        "review_rate": 4.9,
        "customer_name": "Connie Franklin",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Dennis C.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-13",
        "review_rate": 4.9,
        "customer_name": "Terry Bean",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Dwayne E.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-09",
        "review_rate": 4.9,
        "customer_name": "Sarah Hines",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Tyler H.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-12",
        "review_rate": 4.9,
        "customer_name": "Carolyn Wolf",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Gina W.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-22",
        "review_rate": 4.8,
        "customer_name": "Barbara Trujillo",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Charles G.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-02",
        "review_rate": 4.8,
        "customer_name": "Adam Hall",
        "service_type": "Garage Door Opener",
        "tech_name": "Cory A.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-28",
        "review_rate": 4.9,
        "customer_name": "Nicole Padilla",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Kelsey L.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-09",
        "review_rate": 4.8,
        "customer_name": "Chad Kim",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "David D.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-10",
        "review_rate": 4.8,
        "customer_name": "Jacqueline Garcia",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Matthew T.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-07",
        "review_rate": 4.9,
        "customer_name": "Carla Holmes",
        "service_type": "Garage Door Installation",
        "tech_name": "Duane L.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-20",
        "review_rate": 4.9,
        "customer_name": "Rodney Warren",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Mark H.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-27",
        "review_rate": 4.8,
        "customer_name": "Monique Hall",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jay S.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-01",
        "review_rate": 4.9,
        "customer_name": "Larry Compton",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Carol H.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    }
]
export default LocationReviewsData;
