import React, { useState } from 'react'
import emailjs from 'emailjs-com';
import './Contact.scss'
import { Helmet } from "react-helmet";
import NavigatorPath from "../../Tools/NavigatorPath/NavigatorPath"
import { useNavigate } from 'react-router-dom';
import contactusbg from '../../Images/contactusbg.png'
import GarageImg3 from '../../Images/GarageImg3.jpg'
import bgnobgs from '../../Images/bgnobgs.png'

export default function Contact() {
    const navigate = useNavigate()

    function sendEmail(e) {
        // setFormSubmiting(true)
        e.preventDefault();    //This is important, i'm not sure why, but the email won't send without it

        emailjs.sendForm('service_fqud6um', 'template_vdkxpnd', e.target, 'QeqW-bXHRNjRmhZ6I')
            .then((result) => {
                // setPage1(false)
                // setPage2(false)
                // setPage3(true)
                // setFormSubmiting(false)
                // navigate('/thankpage')
                // window.location.reload()  //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior) 
                navigate('/thanks')

            }, (error) => {
                console.log(error.text);
            });
    }

    return (
<div className="Contact">
    <Helmet>
        {/* <!-- TITLE -->  */}
        <title>Contact Us | Huntington Beach Garage Door | 657-258-2617</title>
        <meta name="title" property="title" content="Contact Us | Huntington Beach Garage Door | 657-258-2617" data-react-helmet="true" />
        <meta name="og:title" property="og:title" content="Contact Us | Huntington Beach Garage Door | 657-258-2617" data-react-helmet="true" />
        {/* <!-- META --> */}
        <meta name="og:locale" property="og:locale" content="en_US" data-react-helmet="true" />
        <meta name="og:type" property="og:type" content="article" data-react-helmet="true" />
        <meta name="og:image" property="og:image" content="article" data-react-helmet="true" />
        <meta name="og:url" property="og:url" content="https://www.garagedoorhuntingtonbeach.org/contact" data-react-helmet="true" />
        <meta name="description" content="Contact Huntington Beach Garage Door | 657-258-2617 for all your garage door needs. Get expert advice and schedule services today!" data-react-helmet="true" />
        <meta name="og:description" property="og:description" content="Contact Huntington Beach Garage Door | 657-258-2617 for all your garage door needs. Get expert advice and schedule services today!" data-react-helmet="true" />
        <meta name="og:site_name" property="og:site_name" content="Contact Us | Huntington Beach Garage Door | 657-258-2617" data-react-helmet="true" />
        <meta name="keywords" content="Garage Door, Garage Door Repair, Garage Door Installation, Garage Door Maintenance, Garage Door Opener Installation, Garage Door Springs Replacement, Garage Door Services" data-react-helmet="true" />
    </Helmet>
    <div className="MainBanner">
        <div className="MainBannerLeft">
            <h1>CONTACT US</h1>
            <h2>Monday - Sunday 8 a.m. - 8:00 p.m. ET</h2>
        </div>
        <div className="MainBannerRight">
            <img src={bgnobgs} alt="Huntington Beach Garage Door Contact Us" />
            <div className="MainBannerRightBubble1"></div>
            <div className="MainBannerRightBubble2"></div>
        </div>
    </div>
    <div className="MainBanner2Top2">
        <div className="MainBanner2TopBox">
            <h2>Here’s How We Can Assist You Today</h2>
            <div className="MainBanner2TopBoxMenu">
                <div className="MainBanner2TopBoxBox">
                    <h3>Inquire About Our Services</h3>
                    <a target='_blank' href="mailto:info@garagedoorhuntingtonbeach.org">info@garagedoorhuntingtonbeach.org</a>
                    <a target='_blank' href="tel:657-258-2617">657-258-2617</a>
                </div>
                <div className="MainBanner2TopBoxBox">
                    <h3>Mailing Address</h3>
                    <a>Huntington Beach, California</a>
                </div>
                <div className="MainBanner2TopBoxBox">
                    <h3>Partner with Us</h3>
                    <a target='_blank' href="mailto:admin@garagedoorhuntingtonbeach.org">admin@garagedoorhuntingtonbeach.org</a>
                </div>
            </div>
        </div>
    </div>
    
    <div className="ContactBanner2">
        <h2>Is There Anything Else You'd Like to Know?</h2>
        <a href='/faq'>Feel free to explore our Frequently Asked Questions (FAQ) page.</a>
    </div>
    <div className="ContactFirst">
        <div className="ContactFirstBody">
            <form className="contact-form" onSubmit={sendEmail}>
                <h4>Don't Hesitate to Reach Out!</h4>
                <h5>If you have questions about our services or need assistance, please email us. Our team is here to help!</h5>
                <h2>Full Name</h2>
                <input type="text" name="Full_Name" required />
                <h2>Email</h2>
                <input type="email" name="Email" required />
                <div className='ContactFirsthhh'>
                    <div className="ContactFirsthhhBox">
                        <h2>Phone</h2>
                        <input type="tel" name="Phone" required />
                    </div>
                    <div className="ContactFirsthhhBox">
                        <h2>State</h2>
                        <input type="text" name="State" required />
                    </div>
                </div>
                <h2>Message</h2>
                <textarea className="Message" name="Message" id="" cols="30" rows="10" required></textarea>
                <input type="submit" value="SEND" />
                <input type="hidden" name="Company_From" defaultValue={`Huntington Beach, CA Garage Door | ROTO`} />
                <input type="hidden" name="Company" defaultValue={`Huntington Beach, CA Garage Door | ROTO`} />
            </form>
        </div>
    </div>
    <NavigatorPath />
</div>

    )
}

